import React from 'react';

import "./Error404.scss";

export default function Error404() {
    return (
        <div>
            <h2>Error404</h2>
        </div>
    )
}
