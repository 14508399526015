import React, { useState, useEffect } from 'react';
import SignInSingUp from "./page/SignInSingUp";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "./utils/contexts";
import { isUserLogedApi } from "./api/auth";
import Routing from "./routes/Routing";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Reset from "./page/Reset";

export default function App() {
  const [user, setUser] = useState(null);// {name: "Agustin"}
  const [loadUser, setLoadUser] = useState(false);
  const [refreshChecklogin, setRefreshCheckLogin] = useState(false);

  useEffect(() => {
    setUser(isUserLogedApi());
    setRefreshCheckLogin(false);
    setLoadUser(true);
  }, [refreshChecklogin]);

  if(!loadUser) return null;
  
  /*
  <div className={'container'}>
          {providers.map(provider => 
            <OAuth provider={provider} key={provider} socket={socket} />
          )}
        </div>
  */
  return (
    <AuthContext.Provider value={user}>
      {user ? (
        <Routing setRefreshCheckLogin={setRefreshCheckLogin} />
        )
       : (
        <Router>
          <Switch>
              <Route path={"/reset/:token"} exact={true}>
                <Reset/>
              </Route>
              <Route path={"*"}>
                <SignInSingUp setRefreshCheckLogin={setRefreshCheckLogin} refreshChecklogin={refreshChecklogin} />
              </Route>
          </Switch>
        </Router>
       )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        graggable
        pauseOnHover
       />
    </AuthContext.Provider>
  );
}
