import React, { useState, useEffect } from 'react';
import BasicLayout from "../../layout/BasicLayout";
import { getPasapets, getMetropases } from "../../api/pasaportes";
import MetropolitanoPng from "../../assets/png/parque-metropolitano.png";
import icoServicios from "../../assets/svg/servicios.svg";
import icoEncuesta from "../../assets/svg/customer.png";
import icoParkimovil from "../../assets/svg/parkimovil-logo-h.png";
import icoPasavantes from "../../assets/svg/pasavantes-pasaportes.svg";
import { map } from "lodash";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { getEncuestaApi } from "../../api/utils";
import { Link } from "react-router-dom";
import IcoSalir from "../../assets/svg/salir.svg";
import IcoPaseNuevo from "../../assets/svg/pase-menu.svg";
import { SUB_DIR,API_HOST } from "../../utils/constant";
import { logoutApi } from "../../api/auth";


import "./Servicios.scss";

export default function Servicios(props) {
    const { setRefreshCheckLogin } = props;
    const [encuestaUrl, setEncuestaUrl] = useState(false);
    const [dispositivo, setDispositivo] = useState(null)

    useEffect(() => {

        getEncuestaApi().then(response => {
            if(response){
                let url = response[0].url
                url = url.replace('http:', '')
                url = url.replace('https:', '')
                
                setEncuestaUrl(url)
            }
        })
        .catch(() => {
            console.log("error al obtener url encuesta")
        });

        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        //Android detection
        if (/android/i.test(userAgent)) {
            setDispositivo("Android")
            
        }
        // iOS detection 
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setDispositivo("iOS")
            
        }
        
    }, [])

    const logout = () => {
        window.open(`${API_HOST}/logout`, "_self");
        logoutApi();
        //setRefreshCheckLogin(true);
    }
    
//
    return (
    <BasicLayout page="servicios" className="user" setRefreshCheckLogin={setRefreshCheckLogin} >
        <div className="user__pases">
          <Link to="/#">
          <img className="logo" src={MetropolitanoPng} alt="Logo" />
          </Link>

          <div className="sec-salir-pasaportes">
                <Link to={`/${SUB_DIR}metropases`} onClick={logout}>
                <div className="float-right caja-salirr movil-btn-o"><img className="salirr" src={IcoSalir} alt="Salir" /> Salir</div>
                </Link>
                <Link to={`/${SUB_DIR}#`} >
                <div className="float-right caja-salirr"><img className="salirr" src={IcoPaseNuevo} alt="Pases" /> Ver pases</div>
                </Link>
            </div>

          <div className="divisor"><h5><img className="ico-titulos" src={icoServicios} alt="Servicios" />Sitios de Interés</h5></div>
          <Container>
            <Row className="botones-pasapor mt-4">
                <div className="col-sm-6 text-center  mb-4">
                    <div className="h5 text-left titulo-leyv">
                        Encuesta
                    </div>
                    <div className="text-left mb-1 txt-leyenda-pase mh-pase-l">
                        ¡Tu opinión es muy importante! Ayúdanos a responder la siguiente encuesta y juntos mejoremos este espacio
                    </div>
                
                <Link to={encuestaUrl+" "} target="_blank">
                    <img className="ico-bigs ico-bigs-servicios ico-encuestas" src={icoEncuesta} alt="Encuesta" />
                    
                </Link>
                </div>
                
                <div className="col-sm-6 text-center  mb-4">
                    <div className="h5 text-left titulo-leyv">
                        Parkimovil
                    </div>
                    <div className="text-left mb-1 txt-leyenda-pase mh-pase-l">
                        ¿Ingresas por el acceso Poniente (Balcones)? Para tu comodidad puedes descargar la APP Parkimovil desde tu celular para ingresar más rápido a este estacionamiento
                    </div>
                <Link to={dispositivo == "iOS" ? "//apps.apple.com/es/app/parkimovil/id954503274" : "//play.google.com/store/apps/details?id=com.parkimovil.app&hl=es_419"} target="_blank">
                    <img className="ico-bigs ico-bigs-servicios ico-parkimovil" src={icoParkimovil} alt="Parkimovil" />
                </Link>
                </div>
            </Row>
            <Row className="botones-pasapor mt-4">
                <div className="col-sm-6 text-center  mb-4">
                    <div className="h5 text-left titulo-leyv">
                    ¡El universo del parque!
                    </div>
                    <div className="text-left mb-1 txt-leyenda-pase mh-pase-l">
                    Te invitamos a conocer el "universo" de actividades que puedes realizar en el parque a través de nuestro Manual de Usos.<br></br>
                    Conócelo y saca el mayor provecho a este espacio.
                    </div>
                
                
                </div>
                
                
            </Row>
          </Container>
          <div>
              
              
              
          </div>

        </div>
    </BasicLayout>
    )
}


