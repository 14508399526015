import React, { useState, useEffect } from 'react';
import BasicLayout from "../../layout/BasicLayout";
import { getPasapets } from "../../api/pasaportes";
import MetropolitanoPng from "../../assets/png/parque-metropolitano.png";
import icoPasapets from "../../assets/svg/pasapets.svg";
import icoCaracter from "../../assets/svg/caracter.svg";
import icoFolioPasapets from "../../assets/svg/folio-pasapets.svg";
import icoMascota from "../../assets/svg/mascota.svg";
import icoVigencia from "../../assets/svg/vigencia-pasapets.svg";
import { map } from "lodash";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import IcoSalir from "../../assets/svg/salir.svg";
import { SUB_DIR,API_HOST } from "../../utils/constant";
import { logoutApi } from "../../api/auth";
 
import "./Pasapets.scss";
import { faDove } from '@fortawesome/free-solid-svg-icons';

export default function Pasapets(props) {
    const { setRefreshCheckLogin } = props;
    const [ refreshAcompanantes, setRefreshAcompanantes ] = useState(null);
    const [ pasaportes, setPasaportes ] = useState(0)
    const [ usuario, setUsuario ] = useState(null);

    useEffect(() => {
        setUsuario(jwtDecode(localStorage.getItem("token")));
        getPasapets().then(response =>{
                setPasaportes(response);
                
            }).catch(() =>{
                setPasaportes([]);
            })
        
    }, [])

    const logout = () => {
        window.open(`${API_HOST}/logout`, "_self");
        logoutApi();
        //setRefreshCheckLogin(true);
    }

    return (
    <BasicLayout page="pasapets" className="user" setRefreshCheckLogin={setRefreshCheckLogin} >
        <div className="user__pases">
          <Link to={`/${SUB_DIR}#`}>
          <img className="logo" src={MetropolitanoPng} alt="Logo" />
          </Link>

            <div className="sec-salir-pasaportes">
                <Link to={`/${SUB_DIR}pasapets`} onClick={logout}>
                <div className="float-right caja-salirr movil-btn-o"><img className="salirr" src={IcoSalir} alt="Salir" /> Salir</div>
                </Link>
            </div>
          <div className="divisor"><h5>
              <img className="ico-titulos" src={icoPasapets} alt="Pasapets" /> Pasapets
              
              </h5></div>
          {pasaportes.length > 0 ? <Container className="contentPasaportes">
                {map(pasaportes, (pasaporte, index) =>(
                    <Pasaporte key={index} pasaporte={pasaporte} usuario={usuario}  />
                ))}
            </Container> : <div className="contentPasaportes"><h5>NO HAY PASAPETS</h5></div>}
            

        </div>
    </BasicLayout>
    )
}

function Pasaporte(props) {
    const{ pasaporte, usuario } = props;
    var fInicio = pasaporte?._f_inicio.split("T");
    var fTermino = pasaporte?._f_termino.split("T");
    return (
        <div>
        <Row className="tarjeta">

            <Col className="mw-30">
                
                <Image className="fotoiden" src={"https://api.kodering.com/static/pasapets/"+pasaporte?._fotoIden1} rounded />
            </Col>
            <Col>
                <Row>
                <Col className="text-muted p-0">
                <div><label><img className="ico-titulos" src={icoFolioPasapets} alt="Folio" /> Folio: </label> {pasaporte?._folio}</div>
                <div><label>Nombre: </label> {pasaporte?._nombre}</div>
                <div><label>Raza: </label> {pasaporte?._raza}</div>
                <div><label>Edad: </label> {pasaporte?._edad} años</div>
                
                </Col>
                <Col className="text-muted p-0">
                <img className="ico-titulos" src={icoMascota} alt="Mascota" />
                <div><label>Color: </label> {pasaporte?._color}</div>
                <div><label>Género: </label> {pasaporte?._genero}</div>
                <div><label>Vigencia: </label> {fTermino[0]}</div>
                </Col>  
                </Row> 
                <Row>
                    <Col className="text-muted p-0">
                    <div><label>Potencialmente peligroso: <span className="badge badge-pill badge-info pt-1">{pasaporte?._ppp == 1 ? "SI" : "NO"}</span></label> <img className="ico-titulos" src={icoCaracter} alt="Carácter" /></div>
                    </Col>
                </Row>
            </Col>
            
               
        </Row>
        <Row className="tarjeta justify-content-md-center">
        <Col className="text-muted" sm={12}>
            <div className="rowNombre"><label >Propietario: {usuario?.username} {usuario?.apellido}</label></div>
            <div className="vigencia" style={{background:pasaporte?._colorstatus}}>
                <div><label><img className="ico-titulos" src={icoVigencia} alt="Estatus" />Estatus: </label> {pasaporte?._statusid} </div>
            </div>
        </Col> 
        </Row>
        </div>
    );
}

