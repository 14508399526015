import React, { useState, useEffect } from 'react';
import BasicLayout from "../../layout/BasicLayout";
import { getPasesApi } from "../../api/user";
import ListPases from "../../components/ListPases";
import MetropolitanoPng from "../../assets/png/parque-metropolitano.png";

import "./Home.scss";

export default function Home(props) {
    const { setRefreshCheckLogin, setRefreshPases, refreshPases } = props;
    const [ pases, setPases ] = useState(null);
    const [ pasesValidos, setPasesValidos ] = useState(null);
    const [ pasesExpirados, setPasesExpirados ] = useState(null);
    const [ pasesHoy, setPasesHoy ] = useState(null);
    const [key, setKey] = useState('hoy');

    useEffect(() => {
        setRefreshPases(false)
        getPasesApi()
            .then(response =>{
                var pasesProximos = response.filter(filtrarValidos)
                setPasesValidos(pasesProximos.sort((a, b) => new Date( a.fecha_visita ) - new Date( b.fecha_visita ) ))
                setPasesExpirados(response.filter(filtrarExpirados))
                setPasesHoy(response.filter(filtrarHoy))
                response.filter(filtrarHoy).length > 0 ? setKey('hoy') 
                : response.filter(filtrarValidos).length > 0 ? setKey('valido') 
                : response.filter(filtrarExpirados).length > 0 ? setKey('expirado') : setKey('hoy')
                
                setPases(response);
            })
            .catch(() =>{
                setPases([]);
            })
    }, [refreshPases])

    function filtrarValidos(obj) {
        if ('status' in obj && obj.status === "VÁLIDO") {
          return true;
        } else {
          return false;
        }
    }

    function filtrarExpirados(obj) {
        if ('status' in obj && obj.status === "EXPIRÓ") {
          return true;
        } else {
          return false;
        }
    }

    function filtrarHoy(obj) {
        if ('status' in obj && obj.status === "HOY") {
          return true;
        } else {
          return false;
        }
    }

    return (
    <BasicLayout page="#" className="user" setRefreshCheckLogin={setRefreshCheckLogin} setRefreshPases={setRefreshPases} refreshPases={refreshPases}>
        <div className="user__pases">
            
          <img className="logo" src={MetropolitanoPng} alt="Logo" />
          <ListPases pases={pasesHoy} className="paseHoy" setRefreshPases={setRefreshPases} />
          <ListPases pases={pasesValidos} className ="" setRefreshPases={setRefreshPases}  />
        </div>
    </BasicLayout>
    )
}
