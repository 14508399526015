import React, { useState, useEffect } from 'react';
import BasicLayout from "../../layout/BasicLayout";
import { getPasavantes } from "../../api/pasaportes";
import MetropolitanoPng from "../../assets/png/parque-metropolitano.png";
import icoPasavantess from "../../assets/svg/pasavantes.svg";
import icoEmbarcacion from "../../assets/svg/embarcacion.svg";
import icoMoto from "../../assets/svg/moto-acuatica.svg";
import { map } from "lodash";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import IcoSalir from "../../assets/svg/salir.svg";
import { SUB_DIR,API_HOST } from "../../utils/constant";
import { logoutApi } from "../../api/auth";

import "./Pasavantes.scss";

export default function Pasavantes(props) {
    const { setRefreshCheckLogin } = props;
    const [ refreshAcompanantes, setRefreshAcompanantes ] = useState(null);
    const [ pasaportes, setPasaportes ] = useState(0)
    const [ usuario, setUsuario ] = useState(null);

    useEffect(() => {
        setUsuario(jwtDecode(localStorage.getItem("token")));
        getPasavantes().then(response =>{
                setPasaportes(response);
            }).catch(() =>{
                setPasaportes([]);
            })
    }, [])

    const logout = () => {
        window.open(`${API_HOST}/logout`, "_self");
        logoutApi();
        //setRefreshCheckLogin(true);
    }
//<img className="ico-titulos" src={irAtras} alt="Ir atras" />
    return (
    <BasicLayout page="pasapets" className="user" setRefreshCheckLogin={setRefreshCheckLogin} >
        <div className="user__pases">
          <Link to="/#">
          <img className="logo" src={MetropolitanoPng} alt="Logo" />
          </Link>

          <div className="sec-salir-pasaportes">
                <Link to={`/${SUB_DIR}metropases`} onClick={logout}>
                <div className="float-right caja-salirr movil-btn-o"><img className="salirr" src={IcoSalir} alt="Salir" /> Salir</div>
                </Link>
            </div>

          <div className="divisor"><h5><img className="ico-titulos" src={icoPasavantess} alt="Pasavantes" />Pasavantes</h5></div>
          {pasaportes.length > 0 ? <Container className="contentPasaportes">
                {map(pasaportes, (pasaporte, index) =>(
                    <Pasaporte key={index} pasaporte={pasaporte} usuario={usuario}  />
                ))}
            </Container> : <div className="contentPasaportes"><h5>NO HAY PASAVANTES</h5></div>}
            

        </div>
    </BasicLayout>
    )
}

function Pasaporte(props) {
    const{ pasaporte, usuario } = props;
    var fInicio = pasaporte?._f_inicio.split("T");
    var fTermino = pasaporte?._f_termino.split("T");
    return (
        <div>
        <Row className="tarjeta">

            <Col className="mw-30">
                
                <Image className="fotoiden" src={"https://api.kodering.com/static/pasavantes/"+pasaporte?._fotoIden1} rounded />
            </Col>
            <Col>
                <Row>
                <Col className="text-muted p-0">
                    <div><label><img className="ico-titulos" src={icoEmbarcacion} alt="Embarcacion" />Folio: </label> {pasaporte?._folio}</div>
                    <div><label>Embarcación: </label> {pasaporte?._lancha}</div>
                    <div><label>Color: </label> {pasaporte?._color}</div>
                    
               
                </Col>
                <Col className="text-muted p-0">
                    <div><label>Vigencia: </label> {fTermino[0]}</div>
                    <div><label>Capacidad máxima: {pasaporte?._ocupacion}</label> </div>
                
                </Col> 
                </Row>
                <Row>
                    <Col className="text-muted p-0">
                    <div><label>Observaciones: </label> {pasaporte?._observaciones}</div>
                    </Col>
                </Row>
                
            </Col>
               
        </Row>
        <Row className="tarjeta justify-content-md-center">
        <Col className="text-muted" sm={12}>
            <div className="rowNombre"><label >Propietario: {usuario?.username} {usuario?.apellido}</label></div>
            <div className="vigencia" style={{background:pasaporte?._colorstatus}} >
                <div><label><img className="ico-titulos" src={icoMoto} alt="Estatus" />Estatus: </label> {pasaporte?._statusid}</div>
            </div>
        </Col> 
        </Row>
        </div>
    );
}

