import React,{ useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PerfilModal from "../modal/PerfilModal";
import PaseModal from "../modal/PaseModal";
import { faHome, faUser, faUsers, faPowerOff, faTicketAlt, faPollH } from "@fortawesome/free-solid-svg-icons";
import { logoutApi } from "../../api/auth";
import useAuth from "../../hooks/useAuth";
import { getUserInfoApi } from "../../api/user";
import { getEncuestaApi } from "../../api/utils";
import { API_HOST, SUB_DIR } from "../../utils/constant";
import LogoWhite from "../../assets/svg/parque-logo.svg";
import Cityparks from "../../assets/png/cityparksalliance.png";
import IcoSalir from "../../assets/svg/salir.svg";
import IcoPaseNuevo from "../../assets/svg/pase-menu.svg";
import IcoPerfil from "../../assets/svg/perfil.svg";
import IcoPerfilStar from "../../assets/svg/star.svg";
import IcoEncuesta from "../../assets/svg/encuesta.svg";
import IcoAcompanante from "../../assets/svg/acompanante.svg";
import IcoPasaportes from "../../assets/svg/pasaportes.svg";
import icoServicios from "../../assets/svg/servicios.svg";
import icoMetropases from "../../assets/svg/Ticket_icon.svg";
import PaseSvg from "../../assets/svg/pase.svg";


import "./LeftMenu.scss";

export default function LeftMenu(props) {
    const { setRefreshCheckLogin, setRefreshPases, page } = props;
    const [showModalPerfil, setShowModalPerfil] = useState(false);
    const [showModalPase, setShowModalPase] = useState(false)
    const [encuestaUrl, setEncuestaUrl] = useState(false)
    const user = useAuth(); 

    useEffect(() => {
        
        getEncuestaApi().then(response => {
            if(response){
                let url = response[0].url
                url = url.replace('http:', '')
                url = url.replace('https:', '')
                setEncuestaUrl(url)
            }
        })
        .catch(() => {
            console.log("error al obtener url encuesta")
        });
    }, []);

    const logout = () => {
        window.open(`${API_HOST}/logout`, "_self");
        logoutApi();
        //setRefreshCheckLogin(true);
    }

    return (
        <div className="left-menu">
            <img className="logo" src={LogoWhite} alt="Logo" />
            
            <div>
            <div>
            <Link to={`/${SUB_DIR}`} >
                <img className="ico-menu" src={PaseSvg} alt="Mis Visitas" />Mis Visitas
            </Link>
            </div>
            
            <div>
            <Link to={`/${SUB_DIR}`+page} onClick={() => setShowModalPase(true)} >
                <img className="ico-menu" src={IcoPaseNuevo} alt="Nuevo pase" /> Nueva Visita
            </Link>
            </div>
            <div>
            <Link to={'/' + page == 'acompanante' ? page : "acompanante"}  >
                <img className="ico-menu" src={IcoAcompanante} alt="Acompañantes" /> Acompañantes
            </Link>
            </div>
            <div>
            <Link to={'/' + page == 'pasaportes' ? page : "pasaportes"}  >
                <img className="ico-menu" src={IcoPasaportes} alt="Pasaportes" /> Pasaportes
            </Link>
            </div>
            <div>
            <Link to={'/' + page == 'metropases' ? page : "metropases"}  >
                <img className="ico-menu" src={icoMetropases} alt="Metropases" /> Metropases
            </Link>
            </div>
            <div>
            <Link to={'/' + page == 'servicios' ? page : "servicios"} >
                <img className="ico-menu" src={icoServicios} alt="Servicios" /> Sitios de Interés
            </Link>
            </div>
            <div>
            <Link to={`/${SUB_DIR}`+page} onClick={() => setShowModalPerfil(true)} >
                <img className="ico-menu" src={user.isa === "1" ? IcoPerfilStar : IcoPerfil} alt="Mi perfil" />Mi Perfil
            </Link>
            </div>
            <div className="salir-btn">
            <Link to={`/${SUB_DIR}`+page} onClick={logout} >
                <img className="ico-menu" src={IcoSalir} alt="Salir" /> cerrar sesión
            </Link>
            </div>
            </div>
            

            <PerfilModal user={user} show={showModalPerfil} setShow={setShowModalPerfil} />
            <PaseModal user={user} show={showModalPase} setShow={setShowModalPase} setRefreshPases={setRefreshPases} />
        </div>
    )
}
