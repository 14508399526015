import React, { useState, useEffect } from 'react';
import BasicLayout from "../../layout/BasicLayout";
import { getMetropases, getEstacionamientos, nuevoEstacionamiento, eliminaQrMetropases, getEstatus } from "../../api/pasaportes";
import MetropolitanoPng from "../../assets/png/parque-metropolitano.png";
import icoMetropases from "../../assets/svg/metropases-secc.svg";
import icoEstacionamientos from "../../assets/svg/estacionamientos.svg";
import icoVer from "../../assets/svg/ver.svg";
import icoEliminar from "../../assets/svg/eliminar.svg";
import icoQR from "../../assets/svg/qr.svg";
import icoPersona from "../../assets/svg/give-ticket.svg";
import { map } from "lodash";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import IcoSalir from "../../assets/svg/salir.svg";
import { SUB_DIR,API_HOST } from "../../utils/constant";
import { logoutApi } from "../../api/auth";
import { toast } from "react-toastify";
import BasicModal from "../../components/modal/BasicModal";

import "./Estacionamientos.scss";

export default function Estacionamientos(props) {
    const { setRefreshCheckLogin } = props;
    const [ refreshAcompanantes, setRefreshAcompanantes ] = useState(null);
    const [ pasaportes, setPasaportes ] = useState(0)
    const [ usuario, setUsuario ] = useState(null);
    const [showModalQR, setShowModalQR] = useState(false);
    const [estatus, setEstatus] = useState(null);
    const [tokenQR, setTokenQR] = useState(null);

    useEffect(() => {
        setUsuario(jwtDecode(localStorage.getItem("token")));
        obtieneListado()
        obtieneEstatus()
    }, [])

    const obtieneListado = () => {
        getEstacionamientos().then(response =>{
            setPasaportes(response.mensaje);
        }).catch(() =>{
            setPasaportes([]);
        })
    }
    const obtieneEstatus = () => {
        getEstatus(2).then(response =>{
            
            setEstatus(response);
        }).catch(() =>{
            setPasaportes([]);
        })
    }

    const logout = () => {
        window.open(`${API_HOST}/logout`, "_self");
        logoutApi();
        //setRefreshCheckLogin(true);
    }
    const generarQr = () => {
        nuevoEstacionamiento().then(response =>{
            
            if(response?.mensaje){
                toast.success(response?.mensaje);
                obtieneListado()
                obtieneEstatus()
            }else{
                toast.warning(response?.error);

            }
            
        }).catch(() =>{
            
        })
    }

    const abrirQR = (token) => {
        setTokenQR(token);
        setShowModalQR(true);
    }

    const eliminarQR = (token) => {
        eliminaQrMetropases(token).then(response =>{
            if(response?.mensaje){
                toast.success(response?.mensaje);
                obtieneListado()
            }else{
                toast.warning(response?.error);

            }
            obtieneListado()
        }).catch(() =>{
            
        })
    }
    

//<img className="ico-titulos" src={irAtras} alt="Ir atras" />
    return (
    <React.Fragment>
    <BasicLayout page="estacionamiento" className="user" setRefreshCheckLogin={setRefreshCheckLogin} >
        <div className="user__pases">
          <Link to="/#">
          <img className="logo" src={MetropolitanoPng} alt="Logo" />
          </Link>

          <div className="sec-salir-pasaportes">
                <Link to={`/${SUB_DIR}estacionamiento`} onClick={logout}>
                <div className="float-right caja-salirr movil-btn-o"><img className="salirr" src={IcoSalir} alt="Salir" /> Salir</div>
                </Link>
            </div>

          <div className="divisor"><h5><Link to={`/${SUB_DIR}metropases`} ><img className="ico-titulos" src={icoMetropases} alt="Metropases" />Metropases</Link> / <img className="ico-titulos" src={icoEstacionamientos} alt="Metropases" /><span>Estacionamientos</span></h5></div>

          <div className="row justify-content-center">
              <div className="col-sm-5 text-center creditos-restantes pt-2">
                  <label>{estatus?.mensaje}</label>
                  {estatus?.credito > 0 ? 
                  <span>ENTRADAS RESTANTES: <span className="badge badge-info pt-1">{estatus?.credito}</span></span>
                   : ""}
              </div>
          </div>

          {estatus?.credito > 0 ?
            <div className="text-center caja-gen-codigo">
            <button type="button" className="btn btn-success mb-2 btn-generarcodigo" onClick={generarQr}>
              <div className="row justify-content-center">
                  <div className="col"><img className="img-qrr" src={icoQR} alt="Generar qr" /></div>
                  <div className="col pt-3">GENERAR CÓDIGO</div>
              </div>
                
            </button>
            </div> : ''}
          

          
            { Object.keys(pasaportes).length > 0 ? <div className="table-responsive">
          <table className="table tbl-codigos-qr">
            <thead>
                <tr>
                    <th scope="col">Credito</th>
                    <th scope="col">Fecha creación</th>
                    <th scope="col">Vigencia</th>
                    <th scope="col" >Ver</th>
                    <th scope="col">Borrar</th>
                </tr>
            </thead><tbody>
                {map(pasaportes, (pasaporte, index) =>(
                    <Pasaporte key={index} pasaporte={pasaporte} usuario={usuario} abrirQR={abrirQR} eliminarQR={eliminarQR}  />
                ))}
            </tbody></table>
          </div> : <div className="text-center creditos-restantes pt-4"><h6>NO HAY CÓDIGOS</h6></div>}
          

          
        </div>
    </BasicLayout>
    <BasicModal show={showModalQR} setShow={setShowModalQR} tipo={"qrestacionamiento"} token={tokenQR} />
    </React.Fragment>
    )
}

function Pasaporte(props) {
    const{ pasaporte, usuario, abrirQR, eliminarQR } = props;
    var vigencia = pasaporte?.vigencia.split("T");
    var d = new Date(pasaporte?.vigencia);
    var horas = d.getHours()
    var minutos = d.getMinutes()
    var creacion = pasaporte?.created_at.split("T");
    return (
        <tr>
            <td> {pasaporte?.creditos_by_usuario+" entrada(s)"} </td>
            <td>{creacion[0]}</td>
            <td>{vigencia[0]+" "+horas+":"+minutos+" hrs."}</td>
            <td><img onClick={() => abrirQR({"token":pasaporte?.token,"inicio":creacion[0],"fin":vigencia[0]})} className="ico-titulos acciones-metros" src={icoVer} alt="Metropases" /></td>
            <td><img onClick={() => eliminarQR(pasaporte?.token)} className="ico-titulos acciones-metros" src={icoEliminar} alt="Metropases" /></td>
        </tr>
    );
}

