import { API_HOST , TOKEN} from "../utils/constant";
import { getTokenApi } from "./auth";

export function agregarNuevo(data) {
    const url = `${API_HOST}/acompanantes/nuevo`;
    let date = new Date(data.fecha_nac);
    let anio = date.getFullYear();
    let month = date.getMonth()+1;
    let day = date.getDate();
    const dataTemp = {
        ...data,
        fecha_nac: data.anio+"-"+data.mes+"-"+data.dia
    };

    var formBody = [];
    for (var property in dataTemp) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(dataTemp[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&"); 

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        },
        body: formBody
    };

    return fetch(url, params).then(response => {
        if(response.status >= 200 && response.status < 300) {
            return response.json();
        }
        return response.json()
    }).then(result => {
        return result;
    }).catch(err => {
        return err;
    });
    
}

export function actualizarAco(data) {
    const url = `${API_HOST}/acompanantes/actualizar/${data.id}`;
    let date = new Date(data.fecha_nac);
    let anio = date.getFullYear();
    let month = date.getMonth()+1;
    let day = date.getDate();
    const dataTemp = {
        ...data,
        fecha_nac: data.anio+"-"+data.mes+"-"+data.dia
    };

    var formBody = [];
    for (var property in dataTemp) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(dataTemp[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&"); 

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        },
        body: formBody
    };

    return fetch(url, params).then(response => {
        if(response.status >= 200 && response.status < 300) {
            return response.json();
        }
        return response.json()
    }).then(result => {
        return result;
    }).catch(err => {
        return err;
    });
    
}

export function getAcompanantes() {
    const url = `${API_HOST}/acompanantes/misacompanantes`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function deleteAcompanante(id) {
    const url = `${API_HOST}/acompanantes/borrar/${id}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}