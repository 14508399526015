import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import { values, size } from "lodash";
import { toast } from "react-toastify";
import { isEmailValid, curpValida } from "../../../utils/validations";
import { updateUserApi, getUserInfoApi } from "../../../api/user";
import { verificarEmail } from "../../../api/auth";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import Select from 'react-select';
import IcoCerrar from "../../../assets/svg/cerrar.svg";
import IcoPerfil from "../../../assets/svg/perfil.svg";
import BotonActualizar from "../../../assets/svg/boton-actualizar.svg";


import "./PerfilModal.scss"

export default function PerfilModal(props) {
    const { show, setShow, user } = props;
    const [formData, setFormData] = useState(initialFormValue);
    const [signUpLoading, setSignUpLoading] = useState(false);
    const [selectedGenero, setSelectedGenero] = useState(null);
    const [selectedMes, setSelectedMes] = useState(null);
    const [selectedDia, setSelectedDia] = useState(null);
    const [emailConfirmado, setEmailConfirmado] = useState(null);
    const optionsMeses = [{value:'01',label: 'Enero'},{value:'02',label:'Febrero'},{value:'03',label:'Marzo'},{value:'04',label:'Abril'},
        {value:'05',label: 'Mayo'},{value:'06',label:'Junio'},{value:'07',label:'Julio'},{value:'08',label:'Agosto'},
        {value:'09',label: 'Septiembre'},{value:'10',label:'Octubre'},{value:'11',label:'Noviembre'},{value:'12',label:'Diciembre'}]
    const optionsDias = [{value:'01',label:'1'},{value:'02',label:'2'},{value:'03',label:'3'},{value:'04',label:'4'},{value:'05',label:'5'},{value:'06',label:'6'},{value:'07',label:'7'},{value:'08',label:'8'},{value:'09',label:'9'},{value:'10',label:'10'},
        {value:'11',label:'11'},{value:'12',label:'12'},{value:'13',label:'13'},{value:'14',label:'14'},{value:'15',label:'15'},{value:'16',label:'16'},{value:'17',label:'17'},{value:'18',label:'18'},{value:'19',label:'19'},{value:'20',label:'20'},
        {value:'21',label:'21'},{value:'22',label:'22'},{value:'23',label:'23'},{value:'24',label:'24'},{value:'25',label:'25'},{value:'26',label:'26'},{value:'27',label:'27'},{value:'28',label:'28'},{value:'29',label:'29'},{value:'30',label:'30'},
        {value:'31',label:'31'}];
    

    const optionsGenero = [
        { value: 'M', label: 'Mujer' },
        { value: 'H', label: 'Hombre' },{ value: 'N', label: 'Prefiero no decirlo' }
      ]

    useEffect(() => {
        getUserInfoApi().then(response => {
            if(response[0]){
                
                let fecha = new Date(response[0].uFecha_nac);
                let resultMes = optionsMeses.filter(obj => {
                    return obj.value === ("0" + (fecha.getMonth() + 1)).slice(-2)
                  })
                let resultDia = optionsDias.filter(obj => {
                    return obj.value === ("0" + String(fecha.getDate())).slice(-2)
                  })
                
                setSelectedDia(resultDia)
                setSelectedMes(resultMes)
                setEmailConfirmado(String(response[0].confirmado))
                
                response[0].genero === "H" ? setSelectedGenero(({value: "H", label: "Hombre"}))
                : response[0].genero === "M" ? setSelectedGenero(({value: "M", label: "Mujer"})) 
                : response[0].genero === "N" ? setSelectedGenero(({value: "N", label: "Prefiero no decirlo"})) : setSelectedGenero(null)
                //setSelectedGenero(((response[0].genero == "H") ? {value: "H", label: "Hombre"} : null));//{value: "M", label: "Mujer"}
                setFormData({...formData,
                    nombre: response[0].uNombre,
                    apellidos: response[0].uApellidos,
                    email: response[0].uEmail,
                    telefono: response[0].uTelefono,
                    fecha_nac: response[0].uFecha_nac ? new Date(response[0].uFecha_nac) : null,
                    mes: ("0" + (fecha.getMonth() + 1)).slice(-2),
                    dia: ("0" + fecha.getDate()).slice(-2),
                    anio: String(fecha.getFullYear()),
                    genero: response[0].genero,
                    curp: response[0].curp})//Date.now()
                
            }
            //console.log(formData)
        })
        .catch(() => {
            console.log("error al obtener catalogo transporte")
        });
    }, [show]);

    const onSubmit = e => {
        e.preventDefault();

        let validCount = 0
        values(formData).some(value => {
            value && validCount++;
            return null;
        });

        

        if(selectedGenero === null || selectedMes === null || selectedDia === null || formData.telefono === "" || formData.nombre === "" || formData.apellidos === "" || formData.email === "" || formData.genero === "" || formData.anio === ""){
            toast.warning("Completa todos los campos del formulario")
        }else{
            // SE VALIDA FECHA DE NACIMIENTO MENOR A 14 AÑOS
            let chosenDate = new Date(formData.anio+'-'+formData.mes+'-'+formData.dia);
            let maxYear = new Date();
            maxYear.setFullYear( maxYear.getFullYear() - 15 );
            if(chosenDate > maxYear){
                toast.warning("La edad mínima son 15 años")
                return false
            }
            if(!isEmailValid(formData.email)){
                toast.warning("Email invalido");
            }else{
                if(formData.curp){
                    //SE VALIDA CURP
                    if(!curpValida(formData.curp)){
                        toast.warning("Tu CURP es invalido")
                        return false
                    }
                }
                
                setSignUpLoading(true);
                updateUserApi(formData).then(response => {
                    if(response?.mensaje){
                        toast.success(response?.mensaje);
                        setShow(false);
                    }
                    if(response?.error){
                        toast.warning(response?.error);
                    }
                    
                })
                .catch(() => {
                    toast.error("Error del servidor, intentelo mas tarde!")
                })
                .finally(() => {
                    setSignUpLoading(false);
                })
            }
        }
    }

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleChangeGenero = selectedGenero => {
        setSelectedGenero(selectedGenero);
        setFormData({...formData, genero: selectedGenero.value})
    };
    const handleChangeMes = selectedMes => {
        setSelectedMes(selectedMes);
        setFormData({...formData, mes: selectedMes.value})
    };
    const handleChangeDia = selectedDia => {
        setSelectedDia(selectedDia);
        setFormData({...formData, dia: selectedDia.value})
    };//tu correo ya se envio a tu cuenta favor de verificarlo
    //
    const verificarCorreo = () => {
        setSignUpLoading(true);
        verificarEmail(formData.email).then(response => {
            if(response?.mensaje){
                toast.success(response?.mensaje, {autoClose: false});
                setEmailConfirmado("enviado")
            }
            if(response?.error){
                toast.warning(response?.error);
            }
            
        })
        .catch(() => {
            toast.error("Error del servidor, intentelo mas tarde!")
        })
        .finally(() => {
            setSignUpLoading(false);
        })
    }

    return (
        <Modal 
        className="perfil-modal"
        show ={show}
        onHide= { () => setShow(false) }
        centered
        size="lg"
        >
            <Modal.Header>
                <Modal.Title>
                    <img src={IcoPerfil} alt="Perfil" />
                    <label>Mi Perfil</label>
                    <img className="cerrar" onClick={() => setShow(false)} src={IcoCerrar} alt="Cerrar" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="leyenda">Completa por favor los siguientes campos</div>
<Form onSubmit={onSubmit}>

<Form.Group>
    <Row>
        <Col>
            <Form.Control 
            defaultValue={formData.nombre}
            name="nombre"
            type="text" 
            placeholder="Nombre *"
            onChange={onChange} />
        </Col>
        <Col>
            <Form.Control 
            defaultValue={formData.apellidos}
            name="apellidos"
            type="text" 
            placeholder="Apellidos *"
            onChange={onChange} />
        </Col>
    </Row>
</Form.Group>
<Form.Group>
    <Form.Control 
    defaultValue={formData.email} 
    name="email" 
    type="email" 
    placeholder="Correo electronico *"
    onChange={onChange} />

      {emailConfirmado === "0" ?
        <Form.Text className="text-muted">
        Tu correo no está verificado, 
        <Button onClick={verificarCorreo} disabled={signUpLoading} variant="primary" size="sm">
        {!signUpLoading ? "enviar correo de confirmación" : <Spinner animation="border" />}
        </Button>
        </Form.Text> : emailConfirmado === "enviado" ? 
        <Form.Text className="text-muted">
        Se envió un correo a tu cuenta de correo electrónico.
        </Form.Text> : ''}
      
</Form.Group>
<Form.Group>
                                        <div className="text-center"><Form.Label>Fecha de nacimiento *</Form.Label></div>
                                        <Row className="grupo-nacimiento">
                                            <Col sm={4}>
                                                <Select
                                                
                                                placeholder="Día"
                                                value={selectedDia}
                                                onChange={handleChangeDia}
                                                options={optionsDias}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Select
                                                
                                                placeholder="Mes"
                                                value={selectedMes}
                                                onChange={handleChangeMes}
                                                options={optionsMeses}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                
                                                <Form.Control 
                                                
                                                defaultValue={formData.anio} 
                                                name="anio" 
                                                type="number" 
                                                placeholder="Año"
                                                onChange={onChange} />
                                            </Col>
                                        </Row>
                                        
                                    </Form.Group>
<Form.Group>
    <Row>
        <Col>
        <Form.Control 
        defaultValue={formData.telefono} 
        name="telefono" 
        type="tel" 
        placeholder="Número telefónico *"
        onChange={onChange} />
        </Col>
        <Col>
        <Select
        placeholder="Genero"
        value={selectedGenero}
        onChange={handleChangeGenero}
        options={optionsGenero}
        />
        </Col>
    </Row>
    
</Form.Group>

<Form.Group>
    <Form.Control 
    defaultValue={formData.curp}
    name="curp" 
    type="text" 
    placeholder="Curp"
    onChange={onChange} />
</Form.Group>
<div className="text-left leyenda-b">Los campos marcados con (*) son obligatorios</div>
<div className="updt"><img className="actualizar" onClick={onSubmit} src={BotonActualizar} alt="Actualizar" /></div>

</Form>

            </Modal.Body>
        </Modal>
    )
}
//fecha_nac: "1995-07-07"
function initialFormValue() {
    return {
        nombre: "",
        apellidos: "",
        email: "",
        telefono: "",
        fecha_nac: null,
        genero: "",
        curp: "",
        dia: "",
        mes: "",
        anio: ""
    }
}
