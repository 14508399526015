import React, { Children } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeftMenu from "../../components/LeftMenu";
import MovilMenu from "../../components/MovilMenu";
import Footer from "../../page/Footer";

import "./BasicLayout.scss";

export default function BasicLayout(props) {
    const { className, setRefreshCheckLogin, children, setRefreshPases, refreshPases, page } = props;
    return (
        <Container className={`basic-layout ${className}`}>
            <Row>
                <Col xs={3} className="basic-layout__menu">
                    <LeftMenu page={page} setRefreshCheckLogin={setRefreshCheckLogin} setRefreshPases={setRefreshPases} />
                </Col>
                <Col xs={9} className="basic-layout__content">
                    {children}
                </Col>
            </Row>
            <Row className="footer-sec">
                <Footer />
            </Row>
            <Row className="menu-movil">
                <Col xs={12} className="basic-layout__menu">
                    <MovilMenu page={page} setRefreshCheckLogin={setRefreshCheckLogin} setRefreshPases={setRefreshPases} />
                </Col>
            </Row>
        </Container>
    )
}
