import React, { useState, useEffect } from 'react';
import BasicLayout from "../../layout/BasicLayout";
import { getAcompanantes, deleteAcompanante } from "../../api/acompanantes";
import ListPases from "../../components/ListPases";
import MetropolitanoPng from "../../assets/png/parque-metropolitano.png";
import AcompanantesModal from "../../components/modal/AcompanantesModal";
import AcompananteAdd from "../../assets/svg/AcompananteAdd.svg";
import irAtras from "../../assets/svg/undo.svg";
import { map } from "lodash";
import Table from "react-bootstrap/Table";
import { faEdit,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { SUB_DIR } from "../../utils/constant";

import "./Acompanantes.scss";

export default function Acompanantes(props) {
    const { setRefreshCheckLogin } = props;
    const [ refreshAcompanantes, setRefreshAcompanantes ] = useState(null);
    const [ showModalAcompanante, setShowModalAcompanante ] = useState(false)
    const [ newAcompanante, setNewAcompanante ] = useState(null)
    const [ acompanantes, setAcompanantes ] = useState(null)
    const [ singleAcompanantes, setSingleAcompanantes ] = useState(false)

    useEffect(() => {
        setRefreshAcompanantes(false)
        getAcompanantes().then(response =>{
                //setPases(response);
                setAcompanantes(response.mensaje)
                
            })
            .catch(() =>{
                setAcompanantes([]);
            })
    }, [refreshAcompanantes])

    

    const addAcompanante = () => {
        setSingleAcompanantes(null)
        setNewAcompanante(true)
        setShowModalAcompanante(true)
    }
    const updateAcompanante = (acompanante) => {
        setSingleAcompanantes(acompanante)
        setNewAcompanante(false)
        setShowModalAcompanante(true)
    }

    return (
    <BasicLayout page="acompanante" className="user" setRefreshCheckLogin={setRefreshCheckLogin} >
        <div className="user__pases">
          <Link to={`/${SUB_DIR}#`}>
          <img className="logo" src={MetropolitanoPng} alt="Logo" />
          </Link>

          <h2>Acompañantes</h2>
          
          <div className="head-acomp">
          <Link to={`/${SUB_DIR}#`}>
          <div className="contenedor-atras">
                <img className="addAcompanante irAtras" src={irAtras} alt="Ir atras" />
            <span>Ver pases</span>
          </div>
          </Link>
          <div className="contenedor-add" onClick={addAcompanante}>
            <img className="addAcompanante" 
                
                src={AcompananteAdd} 
                alt="Agregar acompañante" />
            <span>Agregar acompañante</span>
          </div>
          </div>
          
          
          

          
          <Table striped responsive hover>
            <thead>
                <tr>
                <th>Nombre</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {map(acompanantes, (acompanante, index) =>(
                    <Acompanante key={index} acompanante={acompanante} updateAcompanante={updateAcompanante} setRefreshAcompanantes={setRefreshAcompanantes}  />
                ))}
            </tbody>
          </Table>

        </div>
        <AcompanantesModal setSingleAcompanantes={setSingleAcompanantes} singleAcompanantes={singleAcompanantes} newAcompanante={newAcompanante} show={showModalAcompanante} setShow={setShowModalAcompanante} setRefreshAcompanantes={setRefreshAcompanantes} />
    </BasicLayout>
    )
}

function Acompanante(props) {
    const{ acompanante,updateAcompanante, setRefreshAcompanantes} = props;

    const borrarAco = (id) => {
        deleteAcompanante(id).then(response => {
            if(response?.mensaje){
                toast.success(response?.mensaje);
                setRefreshAcompanantes(true);
            }
            if(response?.error){
                toast.warning(response?.error);
            }
        })
        .catch(() => {
            toast.error("Error del servidor, intentelo mas tarde!")
        })
        
    }

    return (
        <tr>
            <td>{acompanante?.nombre}</td>
            <td className="text-right">
                <Button onClick={()=>updateAcompanante(acompanante)} variant="info"><FontAwesomeIcon  icon={faEdit} /></Button> 
                <Button onClick={()=>borrarAco(acompanante?.acoID)} variant="danger"><FontAwesomeIcon  icon={faTrashAlt} /></Button>
            </td>
        </tr>
    );
}
