import React, { useState, useEffect } from 'react';
import BasicLayout from "../../layout/BasicLayout";
import { getPasapets, getMetropases } from "../../api/pasaportes";
import MetropolitanoPng from "../../assets/png/parque-metropolitano.png";
import icoPasaportes from "../../assets/svg/pasaportes.svg";
import icoMetropases from "../../assets/svg/metropases.svg";
import icoPasapets from "../../assets/svg/pasapets-pasaporte.svg";
import icoPasavantes from "../../assets/svg/pasavantes-pasaportes.svg";
import { map } from "lodash";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { Link } from "react-router-dom";
import IcoSalir from "../../assets/svg/salir.svg";
import IcoPaseNuevo from "../../assets/svg/pase-menu.svg";
import { SUB_DIR,API_HOST } from "../../utils/constant";
import { logoutApi } from "../../api/auth";


import "./Pasaportes.scss";

export default function Pasaportes(props) {
    const { setRefreshCheckLogin } = props;
    

    useEffect(() => {
        
    }, [])

    const logout = () => {
        window.open(`${API_HOST}/logout`, "_self");
        logoutApi();
        //setRefreshCheckLogin(true);
    }
//
    return (
    <BasicLayout page="pasaportes" className="user" setRefreshCheckLogin={setRefreshCheckLogin} >
        <div className="user__pases">
          <Link to="/#">
          <img className="logo" src={MetropolitanoPng} alt="Logo" />
          </Link>

          <div className="sec-salir-pasaportes">
                
                <Link to={`/${SUB_DIR}pasaportes`} onClick={logout}>
                <div className="float-right caja-salirr movil-btn-o"><img className="salirr" src={IcoSalir} alt="Salir" /> Salir</div>
                </Link>
                <Link to={`/${SUB_DIR}#`} >
                <div className="float-right caja-salirr"><img className="salirr" src={IcoPaseNuevo} alt="Pases" /> Ver pases</div>
                </Link>
            </div>

          <div className="divisor"><h5><img className="ico-titulos" src={icoPasaportes} alt="Ir atras" />Pasaportes</h5></div>
          <Container>
            <Row className="botones-pasapor mt-4">
                
                <div className="col-sm-6 text-center mb-4">
                    <div className="h5 text-left titulo-leyv">
                        Pasapets
                    </div>
                    <div className="text-left mb-1 txt-leyenda-pase mh-pase-l">
                        Es necesario contar con PASAPET vigente para ingresar con tu mascota. Para obtenerlo escribe a <b>pasapet.parquemetroleon@gmail.com</b>
                    </div>
                <Link to="pasapets">
                 <div className="back-pasapets btn-general btn-pasaportes">
                    <img className="ico-bigs" src={icoPasapets} alt="Pasapets" />
                    <label>Pasapets</label>
                </div>
                </Link>
                </div>
                <div className="col-sm-6 text-center mb-4">
                    <div className="h5 text-left titulo-leyv">
                        Pasavantes
                    </div>
                    <div className="text-left mb-1 txt-leyenda-pase">
                        Si deseas ingresar con lancha, kayak, moto acuática o alguna otra embarcación, es necesario contar con PASAVANTE vigente. Para obtenerlo escribe a <b>embarcaciones.parquemetroleon@gmail.com</b>
                    </div>
                <Link to="pasavantes">
                <div className="back-pasavantes btn-general btn-pasaportes">
                    <img className="ico-bigs" src={icoPasavantes} alt="Pasavantes" />
                    <label>Pasavantes</label>
                </div>
                </Link>
                </div>
            </Row>
          </Container>
          <div>
              
              
              
          </div>

        </div>
    </BasicLayout>
    )
}


