import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { values, size } from "lodash";
import { toast } from "react-toastify";
import { isEmailValid, curpValida } from "../../utils/validations";
import { signUpApi } from "../../api/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye,faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import Select from 'react-select';

import "./SignUpForm.scss";

export default function SignUpForm(props) {
    const { setKey,keyp } = props;
    const [formRegistro, setFormRegistro] = useState(initialFormRegistro);
    const [signUpLoading, setSignUpLoading] = useState(false);
    const [selectedGenero, setSelectedGenero] = useState(null);
    const [selectedMes, setSelectedMes] = useState(null);
    const [selectedDia, setSelectedDia] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    const optionsGenero = [
        { value: 'M', label: 'Mujer' },
        { value: 'H', label: 'Hombre' },{ value: 'N', label: 'Prefiero no decirlo' }
      ];
    const optionsMeses = [{value:'01',label: 'Enero'},{value:'02',label:'Febrero'},{value:'03',label:'Marzo'},{value:'04',label:'Abril'},
        {value:'05',label: 'Mayo'},{value:'06',label:'Junio'},{value:'07',label:'Julio'},{value:'08',label:'Agosto'},
        {value:'09',label: 'Septiembre'},{value:'10',label:'Octubre'},{value:'11',label:'Noviembre'},{value:'12',label:'Diciembre'}]
    const optionsDias = [{value:'01',label:'1'},{value:'02',label:'2'},{value:'03',label:'3'},{value:'04',label:'4'},{value:'05',label:'5'},{value:'06',label:'6'},{value:'07',label:'7'},{value:'08',label:'8'},{value:'09',label:'9'},{value:'10',label:'10'},
        {value:'11',label:'11'},{value:'12',label:'12'},{value:'13',label:'13'},{value:'14',label:'14'},{value:'15',label:'15'},{value:'16',label:'16'},{value:'17',label:'17'},{value:'18',label:'18'},{value:'19',label:'19'},{value:'20',label:'20'},
        {value:'21',label:'21'},{value:'22',label:'22'},{value:'23',label:'23'},{value:'24',label:'24'},{value:'25',label:'25'},{value:'26',label:'26'},{value:'27',label:'27'},{value:'28',label:'28'},{value:'29',label:'29'},{value:'30',label:'30'},
        {value:'31',label:'31'}];

    useEffect(() => {
        setFormRegistro(initialFormRegistro)
        setSelectedGenero(null)
        setSelectedMes(null)
        setSelectedDia(null)
    }, [keyp])

    const onSubmitRegistro = e => {
        e.preventDefault();
        setIsLoading(true)

        if(formRegistro.nombre === "" || formRegistro.apellidos === "" || formRegistro.email === "" || formRegistro.clave === ""
        || formRegistro.clave2 === "" || formRegistro.telefono === "" || formRegistro.genero === "" || formRegistro.mes === ""
        || formRegistro.dia === "" || formRegistro.anio === ""){
            toast.warning("Completa todos los campos del formulario")
            setIsLoading(false)
        }else{
            //SE VALIDA LONGITUD DE CAMPO NOMBRE
            if(formRegistro.nombre.length < 3){
                toast.warning("Nombre debe contener almenos 3 caracteres")
                setIsLoading(false)
                return false
            }
            //SE VALIDA LONGITUD DE CAMPO APELLIDOS
            if(formRegistro.apellidos.length < 3){
                toast.warning("Apellidos debe contener almenos 3 caracteres")
                setIsLoading(false)
                return false
            }

            if(formRegistro.curp != ""){
                //SE VALIDA CURP
                if(!curpValida(formRegistro.curp)){
                    toast.warning("Tu CURP es invalido")
                    setIsLoading(false)
                    return false
                }
            }
            
            // SE VALIDA FECHA DE NACIMIENTO MENOR A 13 AÑOS
            let chosenDate = new Date(formRegistro.anio+'-'+formRegistro.mes+'-'+formRegistro.dia);
            let maxYear = new Date();
            maxYear.setFullYear( maxYear.getFullYear() - 15 );
            if(chosenDate > maxYear){
                toast.warning("La edad mínima son 15 años")
                setIsLoading(false)
                return false
            }
            //SE VALIDA LONGITUD DE CAMPO TELEFONO
            if(formRegistro.telefono.length < 7){
                toast.warning("El telefono es invalido")
                setIsLoading(false)
                return false
            }
            //SE VALIDA EL EMAIL
            if(!isEmailValid(formRegistro.email)){
                toast.warning("El Email es invalido")
                setIsLoading(false)
                return false
            }
            //SE VALIDA LONGITUD DE LA CONTRASEÑA
            if(formRegistro.clave.length < 8){
                toast.warning("Las contraseñas debe ser mayor o igual a 8 caracteres")
                setIsLoading(false)
                return false
            }
            //SE VALIDA CONTRASEÑAS COINCIDAN
            if(formRegistro.clave != formRegistro.clave2){
                toast.warning("Las contraseñas no coinciden")
                setIsLoading(false)
                return false;
            }
            signUpApi(formRegistro).then(response => {
                if(response?.mensaje){
                    setIsLoading(false)
                    toast.success(response?.mensaje); 
                    setKey("login");
                }
                if(response?.error){
                    setIsLoading(false)
                    toast.error(response?.error)
                }
            })
            .catch((err) => {
                console.log(err)
            });
        }
        
    }

    const onChangeRegistro = e => {
        setFormRegistro({ ...formRegistro, [e.target.name]: e.target.value })
    }
    const handleChangeGenero = selectedGenero => {
        setSelectedGenero(selectedGenero);
        setFormRegistro({...formRegistro, genero: selectedGenero.value})
    };
    const handleChangeMes = selectedMes => {
        setSelectedMes(selectedMes);
        setFormRegistro({...formRegistro, mes: selectedMes.value})
    };
    const handleChangeDia = selectedDia => {
        setSelectedDia(selectedDia);
        setFormRegistro({...formRegistro, dia: selectedDia.value})
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    return (
        <Form onSubmit={onSubmitRegistro}>
                                    <Form.Group>
                                        <Form.Control 
                                        defaultValue={formRegistro.nombre} 
                                        name="nombre" 
                                        type="text" 
                                        placeholder="Nombre(s) *"
                                        onChange={onChangeRegistro} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control 
                                        defaultValue={formRegistro.apellidos} 
                                        name="apellidos" 
                                        type="text" 
                                        placeholder="Apellidos *"
                                        onChange={onChangeRegistro} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control 
                                        defaultValue={formRegistro.curp} 
                                        name="curp" 
                                        type="text" 
                                        placeholder="CURP"
                                        onChange={onChangeRegistro} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Fecha de nacimiento</Form.Label>
                                        <Row className="grupo-nacimiento">
                                            <Col sm={4}>
                                                <Select
                                                placeholder="Día *"
                                                value={selectedDia}
                                                onChange={handleChangeDia}
                                                options={optionsDias}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Select
                                                placeholder="Mes *"
                                                value={selectedMes}
                                                onChange={handleChangeMes}
                                                options={optionsMeses}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                
                                                <Form.Control 
                                                defaultValue={formRegistro.anio} 
                                                name="anio" 
                                                type="number" 
                                                placeholder="Año *"
                                                onChange={onChangeRegistro} />
                                            </Col>
                                        </Row>
                                        
                                    </Form.Group>
                                    <Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Control 
                                                defaultValue={formRegistro.telefono} 
                                                name="telefono" 
                                                type="tel" 
                                                placeholder="Teléfono *"
                                                onChange={onChangeRegistro} />
                                            </Col>
                                            <Col>
                                                <Select
                                                placeholder="Género *"
                                                value={selectedGenero}
                                                onChange={handleChangeGenero}
                                                options={optionsGenero}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                    
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control 
                                        defaultValue={formRegistro.email} 
                                        name="email" 
                                        type="email" 
                                        placeholder="Correo electrónico *"
                                        onChange={onChangeRegistro} />
                                    </Form.Group>
                                    <Form.Group className="pass-wrapper">
                                        <Form.Control 
                                        defaultValue={formRegistro.clave} 
                                        name="clave" 
                                        type={passwordShown ? "text" : "password"}
                                        placeholder="Contraseña *"
                                        onChange={onChangeRegistro} />
                                        <i onClick={togglePasswordVisiblity}><FontAwesomeIcon icon={passwordShown ? faEyeSlash : faEye} /></i>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control 
                                        defaultValue={formRegistro.clave2} 
                                        name="clave2" 
                                        type={passwordShown ? "text" : "password"} 
                                        placeholder="Confirmar contraseña *"
                                        onChange={onChangeRegistro} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Text className="text-muted text-left">
                                            Los campos marcados con (*) son obligatorios
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Text className="text-muted text-left">
                                        Al registrarte, aceptas la <a href="https://www.parquemetroleon.com/assets/avisoprivacidad2020.pdf" target="_blank">política de privacidad</a> y <a href="http://parquemetroleon.com/assets/lineamientospml.pdf" target="_blank">los lineamientos</a> del Parque Metropolitano de León
                                        </Form.Text>
                                    </Form.Group>
                                    <Button disabled={isLoading} variant="outline-primary" type="submit" >
                                        
                                        {isLoading ? <Spinner animation="border" /> : "Registrarme"}
                                    </Button>
                                </Form>
    )
}

function initialFormRegistro() {
    return {
        email: "",
        clave: "",
        clave2: "",
        nombre: "",
        apellidos: "",
        curp: "",
        telefono: "",
        genero: "",
        mes: "",
        dia: "",
        anio: ""
    }
}