import { API_HOST , TOKEN, API_PETS} from "../utils/constant";
import { getTokenApi } from "./auth";

export function getPasapets() {
    const url = `${API_PETS}/pasapets/user/1`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getPasavantes() {
    const url = `${API_PETS}/pasavantes/user/1`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getMetropases() {
    const url = `${API_HOST}/users/status_metropase/`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getEstacionamientos() {
    const url = `${API_HOST}/metropases/estacionamientos`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function nuevoEstacionamiento() {
    const url = `${API_HOST}/metropases/nuevo/estacionamiento`
    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        //if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getQrEstacionamientos(token) {
    const url = `${API_HOST}/metropases/qrimagen/estacionamiento/${token}`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.blob();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getQrPersonal(token) {
    const url = `${API_HOST}/metropases/qrimagen/accesos/${token}`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.blob();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getPersonales() {
    const url = `${API_HOST}/metropases/personales`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function nuevoPersonal(creditos) {
    const url = `${API_HOST}/metropases/nuevo/`
    var formBody = [];
    formBody.push("creditos =" + parseInt(creditos));
    const params = {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        },
        body: JSON.stringify({creditos: parseInt(creditos)})
    }

    return fetch (url, params).then(response =>{
        //if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function eliminaQrMetropases(token) {
    const url = `${API_HOST}/metropases/delete/${token}`
    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getEstatus(tipo) {//users/status_metropase/1
    const url = `${API_HOST}/users/status_metropase/${tipo}`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}






