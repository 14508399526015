import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { recover } from "../../api/auth";
import { Link } from "react-router-dom";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../Footer";
import MetropolitanoPng from "../../assets/png/metropolitano.png";
import Spinner from "react-bootstrap/Spinner";

import "./Reset.scss";

function Reset(props) {
    const { match } = props;
    const [mensaje, setMensaje] = useState(null); 
    const [goback, setGoback] = useState(null); 
    const { params } = match;
    const [formRecover, setFormRecover] = useState(initialFormRecover);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        
        formRecover.token = params.token;
        
    }, [])

    const onChangeRecover = e => {
        setFormRecover({ ...formRecover, [e.target.name]: e.target.value })
        }
      const onSubmitRecover = e => {
        e.preventDefault();
        setIsLoading(true)
        if(formRecover.newPass){
            if(formRecover.newPass.length < 8){
                toast.warning("Las contraseñas debe ser mayor o igual a 8 caracteres")
                setIsLoading(false)
                return false
            }
            if(formRecover.newPass === formRecover.newPass2){
                recover(formRecover).then(response => {
                   
                    if(response?.mensaje){
                        setMensaje(<Alert variant={"info"}>{response.mensaje}</Alert>)
                        setGoback(<div className="text-center"><Link to="/#" ><FontAwesomeIcon icon={faAngleLeft}/> Regresar</Link></div>)
                    }
                    if(response?.error){
                        toast.warning(response.error)
                    }
                    setIsLoading(false)
                    
                })
                .catch(() => {
                    console.log("error Registro")
                });
            }else{
                toast.warning("Las contraseñas no coinciden")
                setIsLoading(false)
            }
        }else{
            toast.warning("Llena todos los campos")
            setIsLoading(false)
        }
        
        
      }

    return (
        <div className="reset-page">
        <div className="header-banner"><img src={MetropolitanoPng} alt="Metropolitano" /></div>
        <Container className={`reset-pass`}>
        <Row className="justify-content-md-center">
             
            <Col xs={6}>
                <div className="text-center">
                    
                    <h2>¡EL PARQUE CERCA DE TI!</h2>
                    <hr></hr>
                </div>
                <h3> Ingresa una nueva contraseña </h3>
                <Form onSubmit={onSubmitRecover}>
                    <Form.Group>
                        <Form.Control 
                        defaultValue={formRecover.newPass} 
                        name="newPass" 
                        type="password" 
                        placeholder="Nueva contraseña"
                        onChange={onChangeRecover} />

                        <Form.Control 
                        defaultValue={formRecover.newPass2} 
                        name="newPass2" 
                        type="password" 
                        placeholder="Confirma tu contraseña"
                        onChange={onChangeRecover} />
                    </Form.Group>
                    <Button disabled={isLoading} variant="outline-primary" type="submit">
                        {isLoading ? <Spinner animation="border" /> : "Enviar"}
                    </Button>
                </Form>
                {mensaje}
                {goback}
            </Col>
        </Row>
        
    </Container>
    <Row className="sec-footer">
        <Footer />
    </Row>
    </div>
    )
}

function initialFormRecover() {
    return {
        newPass: "",
        token: "",
        newPass2: "" 
    }
}

export default withRouter(Reset);
