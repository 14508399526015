import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import IcoCerrar from "../../../assets/svg/cerrar.svg";
import IcoPerfil from "../../../assets/svg/perfil.svg";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import Select from 'react-select';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import { agregarNuevo, actualizarAco } from "../../../api/acompanantes";
import { toast } from "react-toastify";
import { values, size } from "lodash";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BotonAcompanante from "../../../assets/svg/boton-acompana.svg";

import "./AcompanantesModal.scss";

export default function AcompanantesModal(props) {
    const {show, setShow, newAcompanante, singleAcompanantes, setSingleAcompanantes,setRefreshAcompanantes } = props;
    const [formData, setFormData] = useState(initialFormValue);
    const [selectedGenero, setSelectedGenero] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMes, setSelectedMes] = useState(null);
    const [selectedDia, setSelectedDia] = useState(null);
    
    const optionsGenero = [{ value: 'M', label: 'Mujer' },{ value: 'H', label: 'Hombre' },{ value: 'N', label: 'Prefiero no decirlo' }]
    const optionsMeses = [{value:'01',label: 'Enero'},{value:'02',label:'Febrero'},{value:'03',label:'Marzo'},{value:'04',label:'Abril'},
        {value:'05',label: 'Mayo'},{value:'06',label:'Junio'},{value:'07',label:'Julio'},{value:'08',label:'Agosto'},
        {value:'09',label: 'Septiembre'},{value:'10',label:'Octubre'},{value:'11',label:'Noviembre'},{value:'12',label:'Diciembre'}]
    const optionsDias = [{value:'01',label:'1'},{value:'02',label:'2'},{value:'03',label:'3'},{value:'04',label:'4'},{value:'05',label:'5'},{value:'06',label:'6'},{value:'07',label:'7'},{value:'08',label:'8'},{value:'09',label:'9'},{value:'10',label:'10'},
        {value:'11',label:'11'},{value:'12',label:'12'},{value:'13',label:'13'},{value:'14',label:'14'},{value:'15',label:'15'},{value:'16',label:'16'},{value:'17',label:'17'},{value:'18',label:'18'},{value:'19',label:'19'},{value:'20',label:'20'},
        {value:'21',label:'21'},{value:'22',label:'22'},{value:'23',label:'23'},{value:'24',label:'24'},{value:'25',label:'25'},{value:'26',label:'26'},{value:'27',label:'27'},{value:'28',label:'28'},{value:'29',label:'29'},{value:'30',label:'30'},
        {value:'31',label:'31'}];

    
    
    useEffect(() => {
        
        if(newAcompanante){
            setSelectedMes(null)
            setSelectedDia(null)
            
            setFormData(initialFormValue)
        }else{
            let fecha = new Date(singleAcompanantes?.fecha_nac);
            let resultMes = optionsMeses.filter(obj => {
                    return obj.value === ("0" + (fecha.getMonth() + 1)).slice(-2)
                  })
            let resultDia = optionsDias.filter(obj => {
                    return obj.value === ("0" + String(fecha.getDate())).slice(-2)
                  })
            let resultAnio = singleAcompanantes?.fecha_nac ? String(fecha.getFullYear()) : ""
            setSelectedDia(resultDia)
            setSelectedMes(resultMes)

            setFormData({...formData,
                nombre: singleAcompanantes?.nombre,
                fecha_nac: singleAcompanantes?.fecha_nac ? new Date(singleAcompanantes?.fecha_nac) : null,
                genero: singleAcompanantes?.genero,
                mes: ("0" + (fecha.getMonth() + 1)).slice(-2),
                dia: ("0" + fecha.getDate()).slice(-2),
                anio: resultAnio,
                id: singleAcompanantes?.acoID})

            singleAcompanantes?.genero == "H" ? setSelectedGenero(({value: "H", label: "Hombre"}))
                : singleAcompanantes?.genero == "M" ? setSelectedGenero(({value: "M", label: "Mujer"})) 
                : singleAcompanantes?.genero == "N" ? setSelectedGenero(({value: "N", label: "Prefiero no decirlo"})) : setSelectedGenero(null)
            
           
        }
        
    }, [singleAcompanantes])

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleChangeGenero = selectedGenero => {
        setSelectedGenero(selectedGenero);
        setFormData({...formData, genero: selectedGenero.value})
    };
    const handleChangeMes = selectedMes => {
        setSelectedMes(selectedMes);
        setFormData({...formData, mes: selectedMes.value})
    };
    const handleChangeDia = selectedDia => {
        setSelectedDia(selectedDia);
        setFormData({...formData, dia: selectedDia.value})
    };

    const onSubmit = e => {
        e.preventDefault();
        setIsLoading(true)

        //SE VALIDA CAMPOS VACIOS
        if(formData.nombre === 3 || formData.dia === "" || formData.mes === "" || formData.anio === "" || formData.genero === ""){
            toast.warning("Completa todos los campos del formulario")
            setIsLoading(false);
        }else{
            if(formData?.nombre?.length <= 3){
                toast.warning("El nombre debe tener al menos tres caracteres")
                setIsLoading(false);
                return false;
            }
            // SE VALIDA FECHA DE NACIMIENTO MENOR A 2 AÑOS
            /*let chosenDate = new Date(formData.anio+'-'+formData.mes+'-'+formData.dia);
            let maxYear = new Date();
            maxYear.setFullYear( maxYear.getFullYear() - 2 );
            if(chosenDate > maxYear){
                toast.warning("La edad mínima es de 2 años")
                setIsLoading(false);
                return false
            }*/

            if(newAcompanante){
                agregarNuevo(formData).then(response => {
                    if(response?.mensaje){
                        toast.success(response?.mensaje);
                        setSingleAcompanantes(formData)
                        setRefreshAcompanantes(true);
                        closeModal()
                    }
                    if(response?.error){
                        toast.warning(response?.error);
                    }
                })
                .catch(() => {
                    toast.error("Error del servidor, intentelo mas tarde!")
                })
                .finally(() => {
                    setIsLoading(false);
                })
            }else{
                actualizarAco(formData).then(response => {
                    if(response?.mensaje){
                        toast.success(response?.mensaje);
                        setRefreshAcompanantes(true);
                        closeModal()
                    }
                    if(response?.error){
                        toast.warning(response?.error);
                    }
                })
                .catch(() => {
                    toast.error("Error del servidor, intentelo mas tarde!")
                })
                .finally(() => {
                    setIsLoading(false);
                })
    
            }
        }
        
        
        
    }
    const closeModal = () => {
        setSelectedGenero(null)
        setSingleAcompanantes(null)
        setFormData(initialFormValue)
        setShow(false)
    }

    return (
        <Modal
            className="Acompanantes"
            show={show}
            onHide={closeModal}
            centered
            size="lg"
        >
           <Modal.Header>
               <Modal.Title>
                    <img src={IcoPerfil} alt="Perfil" />
                    <label>{newAcompanante ? 'Agregar' : 'Actualizar' } acompañante</label>
                    <img className="cerrar" onClick={closeModal} src={IcoCerrar} alt="Cerrar" />
               </Modal.Title>
           </Modal.Header>
           <Modal.Body>
<Form onSubmit={onSubmit}>

<Form.Group>
<Form.Control 
        defaultValue={formData.nombre}
        name="nombre"
        type="text" 
        placeholder="Nombre"
        maxLength="50"
        onChange={onChange} />
</Form.Group>

<Form.Group>
                                        <div className="text-center"><Form.Label>Fecha de nacimiento *</Form.Label></div>
                                        <Row className="grupo-nacimiento">
                                            <Col sm={4}>
                                                <Select
                                                placeholder="Día"
                                                value={selectedDia}
                                                onChange={handleChangeDia}
                                                options={optionsDias}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Select
                                                placeholder="Mes"
                                                value={selectedMes}
                                                onChange={handleChangeMes}
                                                options={optionsMeses}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                
                                                <Form.Control 
                                                defaultValue={formData.anio} 
                                                name="anio" 
                                                type="number" 
                                                placeholder="Año"
                                                onChange={onChange} />
                                            </Col>
                                        </Row>
                                        
                                    </Form.Group>

<Form.Group>
    <Select
        placeholder="Género"
        value={selectedGenero}
        onChange={handleChangeGenero}
        options={optionsGenero}
        />
</Form.Group>


<div className="updt text-center">
{isLoading ? <Spinner animation="border" /> :
 newAcompanante ? <img className="nuevo-aco" onClick={onSubmit} src={BotonAcompanante} alt="Agregar" /> :
  <Button disabled={isLoading} className="btn-submitt" variant="primary" type="submit">
    Actualizar acompañante
  </Button>}
    
</div>

</Form>
           </Modal.Body>
        </Modal>
    )
}

function initialFormValue() {
    return {
        nombre: "",
        fecha_nac: null,
        genero: "",
        id: "",
        dia: "",
        mes: "",
        anio: ""
    }
}