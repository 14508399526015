import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import IcoCerrar from "../../../assets/svg/cerrar.svg";
import Button from "react-bootstrap/Button";
import { getQrImagenApi } from "../../../api/user";
import { getQrEstacionamientos, getQrPersonal } from "../../../api/pasaportes";
import Spinner from 'react-bootstrap/Spinner';

import "./BasicModal.scss";
 
export default function BasicModal(props) {
    const {show, setShow, children, pase, tipo, token} = props;
    const[ qrImg , setQrImg ] = useState(null);

    useEffect(() => {
        if(token){
            
            if(tipo === "qrestacionamiento"){
                getQrEstacionamientos(token?.token).then(response => {
                    setQrImg(URL.createObjectURL(response))
                })
            }
            if(tipo === "qrpersonal"){
                getQrPersonal(token?.token).then(response => {
                    setQrImg(URL.createObjectURL(response))
                })
            }
            
        }else{
            if(pase?.status === "HOY" && show === true){
                getQrImagenApi(pase.cCitasID).then(response => {
                    setQrImg(URL.createObjectURL(response))
                })
            }
        }
        
        
    }, [show]);

    
    const descargaQr = e => {
        let tempLink;
        tempLink = document.createElement('a');
        tempLink.href = qrImg;
        if(token){
            tempLink.setAttribute('download', token?.token);
        }else{
            tempLink.setAttribute('download', pase?.cCitasID);
        }
        
        tempLink.click();
    };

    return (
        <Modal
            className="basic-modal modal-qr"
            show={show}
            onHide={() => setShow(false)}
            centered
            size="lg"
        >
           <Modal.Header>
               <Modal.Title>
                    
                    <img className="cerrar" onClick={() => setShow(false)} src={IcoCerrar} alt="Cerrar" />
               </Modal.Title>
           </Modal.Header>
           <Modal.Body>
                
                {qrImg ? <Image  className="qr" src={qrImg} /> : <div className="text-center pt-5 pb-5 mt-5 mb-5"><Spinner animation="border" /></div>}
                <div>
                    <span>{pase?.fecha_visita} </span>
                    {token ? <span>De {token?.inicio} a {token?.fin}</span> :
                    <span>Desde {pase?.hinicio} hasta {pase?.hfin}</span>
                    }
                    
                </div>
                <div className="text-center"><Button onClick={() => descargaQr()} variant="primary">Descargar pase</Button></div>
           </Modal.Body>
        </Modal>
    )
}