import { API_HOST , TOKEN} from "../utils/constant";
import jwtDecode from "jwt-decode";

export function signUpApi(user) {
    const url = `${API_HOST}/signup`;
    
    const userTemp = {
        ...user,
        email: user.email.toLowerCase(),
        nacimiento: user.anio+"-"+user.mes+"-"+user.dia
    };
    delete userTemp.clave2;

    var formBody = [];
    for (var property in userTemp) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(userTemp[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&"); 

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formBody
    };

    return fetch(url, params).then(response => {
        if(response.status >= 200 && response.status < 300) {
            return response.json();
        }
        return response.json()
    }).then(result => {
        return result;
    }).catch(err => {
        return err;
    });
    
}

export function signInTwitter(){
    
}

export function signInApi(user) {
    const url = `${API_HOST}/login`;
    const userTemp = {
        ...user,
        email: user.email.toLowerCase()
    };

    var formBody = [];
    for (var property in userTemp) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(userTemp[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formBody
    };
    
    return fetch(url, params).then(response => {
        if(response.status >= 200 && response.status < 300) {
            return response.json();
        }
        return response.json()
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err;
    });
}

export function forgot(data) {
    const url = `${API_HOST}/forgot`;

    var formBody = [];
    for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formBody
    };
    
    return fetch(url, params).then(response => {
        if(response.status >= 200 && response.status < 300) {
            return response.json();
        }
        return response.json()
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err;
    });
}

export function recover(data) {
    const url = `${API_HOST}/recover`;

    var formBody = [];
    for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formBody
    };
    
    return fetch(url, params).then(response => {
        if(response.status >= 200 && response.status < 300) {
            return response.json();
        }
        return response.json()
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err;
    });
}

export function verificarEmail(email) {
    const url = `${API_HOST}/verificar`;

    var formBody = [];
    formBody.push("email=" + email);
    formBody = formBody.join("&");

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formBody
    };
    
    return fetch(url, params).then(response => {
        if(response.status >= 200 && response.status < 300) {
            return response.json();
        }
        return response.json()
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err;
    });
}
//
export function verificando(token,email) {
    const url = `${API_HOST}/verificando`;

    var formBody = [];
    formBody.push("email=" + email);
    formBody.push("token=" + token);
    formBody = formBody.join("&");

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: formBody
    };
    
    return fetch(url, params).then(response => {
        if(response.status >= 200 && response.status < 300) {
            return response.json();
        }
        return response.json()
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err;
    });
}

export function setTokenApi(token) {
    localStorage.setItem(TOKEN, token);
}

export function getTokenApi() {
    const token = localStorage.getItem(TOKEN);
    if(!token) {
        window.open(`${API_HOST}/logout`, "_self");
        logoutApi();
    }
    if(isExpired(token)) {
        window.open(`${API_HOST}/logout`, "_self");
        logoutApi();
    }
    return localStorage.getItem(TOKEN);
}

export function logoutApi() {
    
    localStorage.removeItem(TOKEN)
}

export function isUserLogedApi() {
    const token = localStorage.getItem(TOKEN);

    if(!token) {
        logoutApi();
        return null;
    }
    if(isExpired(token)) {
        logoutApi();
    }
    return jwtDecode(token);
}

function isExpired(token) {
    const { exp } = jwtDecode(token);
    const expire = exp * 1000;
    const timeout = expire - Date.now();

    if(timeout < 0) {
        return true;
    }
    return false;
}