import React, { useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import IcoCerrar from "../../../assets/svg/cerrar.svg";
import Button from "react-bootstrap/Button";

import "./AlertEliminar.scss";

export default function AlertEliminar(props) {
    const {show, setShow, borrarPaseX, id} = props;

    const nothing = () => {
        
    }

    return (
        <Modal
            className="alert-eliminar"
            show={show}
            onHide={() => nothing()}
            centered
            size="lg"
        >
           <Modal.Header>
               <Modal.Title>
                ¿ Quieres borrar el pase ?
               </Modal.Title>
           </Modal.Header>
           <Modal.Body>
                El pase se borrará permanentemente!
           </Modal.Body>
           <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
                Cancelar
            </Button>
            <Button variant="primary" onClick={() => borrarPaseX(id)}>
                Confirmar
            </Button>
           </Modal.Footer>
        </Modal>
    )
}