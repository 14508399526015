import { API_HOST } from "../utils/constant";

export function getActividadesApi(fecha) {
    const url = `${API_HOST}/utilidades/actividades/${fecha}`
    const params = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getEncuestaApi() {
    const url = `${API_HOST}/admin/app/metro/encuesta`
    const params = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getTransporteApi() {
    const url = `${API_HOST}/utilidades/transporte`
    const params = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getHorariosApi(tipo_actividad,fecha) {
    const url = `${API_HOST}/utilidades/horarios/${tipo_actividad}/${fecha}`
    const params = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}