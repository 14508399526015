import React, { useState, useEffect } from 'react';
import LogoFooter from "../../assets/png/logo-footer.png";
import Facebook from "../../assets/svg/facebook.svg";
import Twitter from "../../assets/svg/twitter.svg";
import getTokenApi from "../../api/auth";

import "./Footer.scss";

export default function Footer() {
    const [islogued, setIslogued] = useState(null)

    useEffect(() => {
        if(localStorage.getItem('token')){
            setIslogued(true)
        }else{
            setIslogued(false)
        }
    }, []);

    return (
        <div className="Footer">
            <div className="contactos">
                <img src={LogoFooter} alt="Logo" />
                <span>Prol. Blvd. Adolfo López Mateos Nte. s/n Col. El Palote, León Guanajuato. C.P. 37130</span>
                <span><b>OFICINAS:</b> Lunes a viernes 8:00 a 15:30 hrs.</span>
                <span><b>PARQUE:</b> Lunes a sábado 6:00 a 21:20 h.  Domingo 6:00 a 20:00 h.</span>
                <span><b>CONTACTO:</b> (477) 7174410 y 11</span>
                <span><b>WHATSAPP:</b> <a href="https://wa.me/+520444776414039?text=Hola%20quiero%20contactárme%20con%20el%20parque%20" target="_blank" >477 641 4039</a></span>
                <div className="icos" >
                    <a href="https://www.facebook.com/parkmetroleon/" target="_blank" ><img src={Facebook} alt="facebook" /></a>
                    <a href="https://twitter.com/parkmetroleon" target="_blank" ><img src={Twitter} alt="twitter" /></a>
                </div>
                {islogued ? <div className="reglamento">
                    <a href="http://parquemetroleon.com/assets/reglamento-parque.pdf" target="_blank">Reglamento del parque</a>
                </div> : ''}
                
                
            </div>
            <div className="copy">
                <span>Copyright © Parque Metropolitano de León 2020</span>
                <a href="https://www.parquemetroleon.com/assets/avisoprivacidad2020.pdf" target="_blank"><span>Aviso de privacidad</span></a>
            </div>
        </div>
    )
}

