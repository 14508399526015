import React, { useState, useEffect } from 'react';
import Image from "react-bootstrap/Image";
import { map } from "lodash";
import moment from "moment";
import "moment/locale/de.js";
import 'moment/locale/es';  // without this line it didn't work
import { getDetallepaseApi, getQrImagenApi, borrarPase } from "../../api/user";
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import BasicModal from "../modal/BasicModal";
import AlertEliminar from "../modal/AlertEliminar";
import PaseSvg from "../../assets/svg/pase.svg";
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { SUB_DIR } from "../../utils/constant";

import "./ListPases.scss";

moment.locale('es');

export default function ListPases(props) {
    const { pases, setRefreshPases, className } = props;
    
    
    return (
        pases?.length ? 
        <div className="list-pases">
            {map(pases, (pase, index) =>(
                <Pase key={index} pase={pase} className={className} setRefreshPases={setRefreshPases} />
            ))}
        </div>
        : className == 'paseHoy' ? 
        <div className="noPases">
            <div className="imgpase"><img src={PaseSvg} alt="Pase" /></div>
            <div className="txtpase">No tienes pases <span>para el día de hoy</span></div>
            <hr />
        </div> : ""
    )
}

function Pase(props) {
    const{ pase, className,setRefreshPases } = props;
    const[ paseInfo , setPaseinfo ] = useState(null);
    const[ qrImg , setQrImg ] = useState(null);
    const monthNames = ["enero", "febrero", "marzo", "abril", "mayo", "junio","julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const daysWeek = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes"];
    const fechaVisita = pase?.fecha_visita.split("-");;
    const [showModalQR, setShowModalQR] = useState(false)
    const [showModaDelete, setShowModalDelete] = useState(false)


    useEffect(() => {
        if(pase){
            getDetallepaseApi(pase.cCitasID).then(response => {
                setPaseinfo(response);
                
            })
        }
    }, [pase]);

    useEffect(() => {
        setRefreshPases(false)
    }, [showModaDelete]);

    const borrarPaseX = (id) => {
        
        borrarPase(id).then(response => {
            setRefreshPases(true)
            setShowModalDelete(false)
            if(response?.mensaje){
                toast.success(response?.mensaje);
            }
            if(response?.error){
                toast.warning(response?.error);
            }
        })
    }

    
    //{qrImg ? <Image onClick={() => descargaQr()} className="qr" src={qrImg} /> : <Spinner animation="border" />}
    //<div className="icoo"></div>
    return (
        
        <Row className={`pase ${className}`}>
            
            <Col sm={12}>
                <div className="body-pase">
                    <Button className="btn-delete" onClick={() => setShowModalDelete(true)} variant="danger"><FontAwesomeIcon  icon={faTrashAlt} /></Button>
                    {pase?.status == "VÁLIDO" ? <div className="badge-status"><Badge variant="warning">PRÓXIMO</Badge></div> 
                    : pase?.status == "HOY" ? <div className="badge-status"><Badge variant="success">HOY</Badge></div> 
                    : ''}
                    
                    
                        <img className="img-actividad" src={pase?.icono === 'ski' || pase?.icono === 'kayak' ? `/${SUB_DIR}`+pase?.icono+".png" : `/${SUB_DIR}`+pase?.icono+".svg"} alt="ico" />
                    

                    <div className="textto">
                    <div className="nombre-pase">{ pase?.aNombre+" "} {pase?.observaciones != undefined ? pase?.observaciones : ""}</div>
                    

                    {pase?.fecha_visita ? <div><span className="fecha">{pase?.DIA_SEMANA+" "+fechaVisita[2]} de {monthNames[parseInt(fechaVisita[1], 10)-1]} de {fechaVisita[0]}</span></div>: <div><Spinner animation="border" /></div>}

                    {pase?.tipo != 2 ? <div><span>Desde {pase?.hinicio} hasta {pase?.hfin}</span></div> : <div></div>}
                    {pase?.total_acompanantes > 1 ? <div><span>{pase?.total_acompanantes} acompañantes</span></div> :
                    pase?.total_acompanantes === 1 ? <div><span>{pase?.total_acompanantes} acompañante</span></div> : ''}
                    

                    
                    {pase?.status == "HOY" ? <Button onClick={() => setShowModalQR(true)} variant="primary">Ver código QR</Button> : <div><span className="dia-indicado">Tu pase se generará el día  indicado</span></div>}
                    </div>
                </div>

            </Col> 
            <BasicModal show={showModalQR} setShow={setShowModalQR} pase={pase} />
            <AlertEliminar show={showModaDelete} setShow={setShowModalDelete} borrarPaseX={borrarPaseX} id={pase.cCitasID}/>
        </Row>
       
    );
}
