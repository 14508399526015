import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { map } from "lodash";
import configRouting from "./configRouting";
import history from '../utils/history';

export default function Routing(props) {
    const { setRefreshCheckLogin } =props;
    const [ refreshPases, setRefreshPases] = useState(false);
    return (
        <Router history={history}>
            <Switch>
                {map(configRouting, (route, index) => (
                    <Route key={index} path={route.path} exact={route.exact}>
                        <route.page setRefreshCheckLogin={setRefreshCheckLogin } setRefreshPases={setRefreshPases} refreshPases={refreshPases} />
                    </Route>
                ))}
            </Switch>
        </Router>
    );
}
