import { API_HOST } from "../utils/constant";
import { getTokenApi } from "./auth";

export function getUserApi(id) {
    const url = `${API_HOST}/obtienedatos?id=${id}`

    const params = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(reponse =>{
        if(reponse.status >= 400) throw null;
        return Response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function updateUserApi(user) {

    const url = `${API_HOST}/users/actualizardatos`;
    const data = {
        ...user,
        email: user.email.toLowerCase(),
        fecha_nac: user.anio+"-"+user.mes+"-"+user.dia
    }

    var formBody = [];
    for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        },
        body: formBody
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .catch((err) => {
            return err;
        });

}

export function generarPaseUserApi(datos) {
    let anio = datos.fecha_visita.getFullYear();
    let dia = ('0' + (datos.fecha_visita.getDate())).slice(-2); 
    let mes = ('0' + (datos.fecha_visita.getMonth()+1)).slice(-2);
    
    const url = `${API_HOST}/users/generarpase`;
    const data = {
        ...datos,
        fecha_visita: anio + "-" + mes + "-" + dia
    }

    var formBody = [];
    for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        
        if(encodedKey === "acompanantesids"){
            formBody.push(encodedKey + "=" + JSON.stringify(data[property]));
        }else{
            var encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        
    }
    formBody = formBody.join("&");

    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        },
        body: formBody
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response.json())
            return response.json()
        })
        .catch((err) => {
            return err;
        });

}

export function getPasesApi() {
    const url = `${API_HOST}/users/mispases/H`
    const params = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function borrarPase(id) {
    const url = `${API_HOST}/users/elimina/${id}`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getDetallepaseApi(uuid) {
    const url = `${API_HOST}/users/verpase/${uuid}`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getQrImagenApi(uuid) {
    const url = `${API_HOST}/users/qrimagen/${uuid}`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.blob();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

export function getUserInfoApi() {
    const url = `${API_HOST}/users/userinfo/`
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            Authorization: `Bearer ${getTokenApi()}`
        }
    }

    return fetch (url, params).then(response =>{
        if(response.status >= 400) throw null;
        return response.json();
    })
    .then (result =>{
        return result;
    })
    .catch(err => {
        return err;
    })
}

