import Home from "../page/Home";
import Error404 from "../page/Error404";
import Acompanantes from "../page/Acompanantes";
import Pasapets from "../page/Pasapets";
import Pasavantes from "../page/Pasavantes";
import Pasaportes from "../page/Pasaportes";
import Metropases from "../page/Metropases";
import Estacionamientos from "../page/Metropases-estacionamiento";
import Personal from "../page/Metropases-personal";
import Servicios from "../page/Servicios";
import ConfirmarEmail from "../page/ConfirmarEmail";
import { SUB_DIR } from "../utils/constant";

export default [
    {
        path: `/${SUB_DIR}`,
        exact: true,
        page: Home
    },
    {
        path: `/${SUB_DIR}acompanante`,
        exact: true,
        page: Acompanantes
    },
    {
        path: `/${SUB_DIR}pasapets`,
        exact: true,
        page: Pasapets
    },
    {
        path: `/${SUB_DIR}pasavantes`,
        exact: true,
        page: Pasavantes
    },
    {
        path: `/${SUB_DIR}pasaportes`,
        exact: true,
        page: Pasaportes
    },
    {
        path: `/${SUB_DIR}estacionamiento`,
        exact: true,
        page: Estacionamientos
    },
    {
        path: `/${SUB_DIR}personal`,
        exact: true,
        page: Personal
    },
    {
        path: `/${SUB_DIR}metropases`,
        exact: true,
        page: Metropases
    },
    {
        path: `/${SUB_DIR}servicios`,
        exact: true,
        page: Servicios
    },
    {
        path: "/confirma/:token",
        exact: true,
        page: ConfirmarEmail
    },
    {
        path: "*",
        page: Error404
    }
]