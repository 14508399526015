import React, {useState, useEffect} from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import BasicModal from "../../components/modal/BasicModal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { setTokenApi, signUpApi, signInApi, forgot } from "../../api/auth";
import { API_HOST } from "../../utils/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter,faFacebook,faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faAngleLeft,faAt,faEye,faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import { values, size } from "lodash";
import MetropolitanoPng from "../../assets/png/banner_app.jpg";
import Footer from "../Footer";
import Spinner from "react-bootstrap/Spinner";
import SignUpForm from "../../components/SignUpForm";


import "./SignInSingUp.scss"

export default function SignInSingUp(props) {
    const { setRefreshCheckLogin, refreshChecklogin } = props;
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    
    //setTokenApi('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySUQiOiJjNzgxMzE1OC1kNWJhLTQxMzItYjM3Zi1kMTE5M2UwNWJjNjgiLCJuYW1lIjoiSm9obiBEb2UiLCJpYXQiOjE1MTYyMzkwMjJ9.c9f44T9G1k3j-op7uxBL3gg___zfq0xkNGuVyiraEgo')

    useEffect(() => {
        
    }, [refreshChecklogin])

    const openModal = content => {
        setShowModal(true);
        setContentModal(content);
    };

    

    return (
        <>
        <Container className="signin-signup" fluid>
            <Row className="justify-content-md-center">
                <div className="header-banner"><img src={MetropolitanoPng} alt="Metropolitano" /></div>
                <RightComponent openModal={openModal} setShowModal={setShowModal} setRefreshCheckLogin={setRefreshCheckLogin} />
            </Row>
            <Row>
                <Footer />
            </Row>
        </Container>
        <BasicModal show={showModal} setShow={setShowModal}>
            {contentModal}
        </BasicModal>
        </>
    );
}

function RightComponent(props) {
    const {openModal, setShowModal, setRefreshCheckLogin} = props;
    const [key, setKey] = useState('login');
    const [formLogin, setFormLogin] = useState(initialFormLogin);
    const [formRecordar, setFormRecordar] = useState(initialFormRecordar);
    const [mensaje, setMensaje] = useState(null); 
    const [showSpinner, setShowSpinner] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    const onChangeLogin = e => {
        setFormLogin({ ...formLogin, [e.target.name]: e.target.value })
    }
    const onSubmitLogin = e => {
        e.preventDefault();
        setShowSpinner(true)
        let validCount = 0
        values(formLogin).some(value => {
            value && validCount++;
            return null;
        });
        if(validCount != size(formLogin)){
            toast.warning("Completa todos los campos del formulario")
            setShowSpinner(false)
        }else{
            signInApi(formLogin).then(response => {
                if(response?.success){
                    setTokenApi(response?.token);
                    setRefreshCheckLogin(true);
                }else{
                    toast.warning(response?.error)
                }
                setShowSpinner(false)
            })
            .catch(() => {
                console.log("error Registro")
            });
        }
        
    }
    const onChangeRecordar = e => {
        setFormRecordar({ ...formRecordar, [e.target.name]: e.target.value })
    }
    const onSubmitRecordar = e => {
        e.preventDefault();
        setShowSpinner(true)
        if(formRecordar.email){
            forgot(formRecordar).then(response => {
                
                if(response?.mensaje){
                    setMensaje(<Alert variant={"info"}>{response.mensaje}</Alert>)
                }
                if(response?.error){
                    toast.warning(response.error)
                }
                setShowSpinner(false)
                
            })
            .catch(() => {
                console.log("error Registro")
            });
        }else{
            toast.warning("Ingresa tu correo")
            setShowSpinner(false)
        }
        
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
      
      
      //<Button onClick={() => openModal(<SignInForm setRefreshCheckLogin={setRefreshCheckLogin} />)} variant="outline-primary">Iniciar sesión</Button>
      

    return (
        <Col className="signin-signup__right" xs={10}>
            <div>
                <h2>¡EL PARQUE CERCA DE TI!</h2>
                <hr></hr>

                <Accordion defaultActiveKey="1">
                    
                    
                    <Accordion.Collapse eventKey="1">
                        <div>
                            <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            >
                                <Tab eventKey="login" title="Login">
                                <Form onSubmit={onSubmitLogin}>
                                    <Form.Group>
                                        <Form.Control 
                                        defaultValue={formLogin.email} 
                                        name="email" 
                                        type="email" 
                                        placeholder="Correo electrónico"
                                        onChange={onChangeLogin} />
                                    </Form.Group>
                                    <Form.Group className="pass-wrapper">
                                        <Form.Control 
                                        defaultValue={formLogin.clave} 
                                        name="clave" 
                                        type={passwordShown ? "text" : "password"}
                                        placeholder="Contraseña"
                                        onChange={onChangeLogin} />
                                        <i onClick={togglePasswordVisiblity}><FontAwesomeIcon icon={passwordShown ? faEyeSlash : faEye} /></i>
                                    </Form.Group>
                                    <Button disabled={showSpinner} variant="outline-primary" type="submit">
                                        {showSpinner ? <Spinner animation="border" /> : "Iniciar Sesión"}
                                    </Button>
                                </Form>
                                </Tab>
                                <Tab eventKey="registro" title="Registro">
                                    <SignUpForm setKey={setKey} keyp={key} />
                                </Tab>
                            </Tabs>
                            <CustomToggle setMensaje={setMensaje} eventKey="2"><span>Olvidé mi contraseña</span> </CustomToggle>
                            
                        </div>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="2">
                        <div>
                            <div className="text-left">Enviaremos un correo con indicaciones para recuperar tu contraseña, ingresa el correo electrónico asociado a tu cuenta.</div>
                        <Form onSubmit={onSubmitRecordar}>
                            <Form.Group>
                                <Form.Control 
                                defaultValue={formRecordar.email} 
                                name="email" 
                                type="email" 
                                placeholder="Correo electrónico"
                                onChange={onChangeRecordar} />
                            </Form.Group>
                            <Button disabled={showSpinner} variant="outline-primary" type="submit">
                                {showSpinner ? <Spinner animation="border" /> : "Enviar"}
                            </Button>
                            
                            {mensaje}
                        </Form>
                        <CustomToggle setMensaje={setMensaje} onClick={() => console.log("HIHIHI") } eventKey="1"><FontAwesomeIcon  icon={faAngleLeft} /> Atras </CustomToggle>
                        </div>
                    </Accordion.Collapse>
                </Accordion>
                
            </div>
        </Col>
    );

    
}
function initialFormLogin() {
    return {
        email: "",
        clave: ""
    }
}
function initialFormRecordar() {
    return {
        email: ""
    }
}

function CustomToggle({ children, eventKey, setMensaje }) {
    //const decoratedOnClick = useAccordionToggle(eventKey);
    const decoratedOnClick = useAccordionToggle(eventKey, () => setMensaje(null),); 
    return (
      <div onClick={decoratedOnClick} className="togglelink">
        {children}
      </div>
    );
  }