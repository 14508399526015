import React, { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { verificando } from "../../api/auth";
import { getUserInfoApi } from "../../api/user";
import { Link } from "react-router-dom";
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../Footer";
import MetropolitanoPng from "../../assets/png/metropolitano.png";
import Spinner from "react-bootstrap/Spinner";

import "./ConfirmarEmail.scss";

function ConfirmarEmail(props) {
    const { match } = props;
    const [mensaje, setMensaje] = useState(null); 
    const [goback, setGoback] = useState(null); 
    const { params } = match;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        
        setIsLoading(true)
        getUserInfoApi().then(response => {
            if(response[0]){
                
                verificando(params.token, response[0].uEmail).then(response => {
                   
                    if(response?.mensaje){
                        setMensaje(<Alert variant={"info"}>{response.mensaje}</Alert>)
                        setGoback(<div className="text-center"><Link to="/#" ><FontAwesomeIcon icon={faAngleLeft}/> Regresar</Link></div>)
                    }
                    if(response?.error){
                        toast.warning(response.error)
                    }
                    setIsLoading(false)
                    
                })
                .catch(() => {
                    console.log("error Registro")
                });
            }
            console.log(response)
        })
        .catch(() => {
            console.log("error Registro")
        });
        
        
    }, [])


    return (
        <div className="confirmar-page">
        <div className="header-banner"><img src={MetropolitanoPng} alt="Metropolitano" /></div>
        <Container className={`confirmar-email`}>
        <Row className="justify-content-md-center">
             
            <Col xs={6}>
                <div className="text-center">
                    
                    <h2>¡EL PARQUE CERCA DE TI!</h2>
                    <hr></hr>
                </div>
                
                {isLoading ? <div className="text-center"><Spinner animation="border" /></div> : ''}
                {mensaje}
                {goback}
            </Col>
        </Row>
        
    </Container>
    <Row className="sec-footer">
        <Footer />
    </Row>
    </div>
    )
}



export default withRouter(ConfirmarEmail);
