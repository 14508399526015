import React, { useState, useEffect } from 'react';
import BasicLayout from "../../layout/BasicLayout";
import { getPersonales, nuevoPersonal, eliminaQrMetropases, getEstatus } from "../../api/pasaportes";
import MetropolitanoPng from "../../assets/png/parque-metropolitano.png";
import icoMetropases from "../../assets/svg/metropases-secc.svg";
import icoPersonal from "../../assets/svg/personal.svg";
import icoVer from "../../assets/svg/ver.svg";
import icoEliminar from "../../assets/svg/eliminar.svg";
import icoQR from "../../assets/svg/qr.svg";
import icoPersona from "../../assets/svg/give-ticket.svg";
import { map } from "lodash";
import { Link } from "react-router-dom";
import jwtDecode from "jwt-decode";
import IcoSalir from "../../assets/svg/salir.svg";
import { SUB_DIR,API_HOST } from "../../utils/constant";
import { logoutApi } from "../../api/auth";
import { toast } from "react-toastify";
import BasicModal from "../../components/modal/BasicModal";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import "./Personal.scss";

export default function Personal(props) {
    const { setRefreshCheckLogin } = props;
    const [ pasaportes, setPasaportes ] = useState(0)
    const [ usuario, setUsuario ] = useState(null);
    const [showModalQR, setShowModalQR] = useState(false);
    const [tokenQR, setTokenQR] = useState(null);
    const [estatus, setEstatus] = useState(null);
    const [formData, setFormData] = useState(initialFormValue);
    const [nPersonas, setNPersonas] = useState(0);

    useEffect(() => {
        setUsuario(jwtDecode(localStorage.getItem("token")));
        obtieneListado()
        obtieneEstatus()
    }, [])

    const obtieneListado = () => {
        getPersonales().then(response =>{
            setPasaportes(response.mensaje);
        }).catch(() =>{
            setPasaportes([]);
        })
    }
    const obtieneEstatus = () => {
        getEstatus(1).then(response =>{
            
            setEstatus(response);
        }).catch(() =>{
            setPasaportes([]);
        })
    }

    const logout = () => {
        window.open(`${API_HOST}/logout`, "_self");
        logoutApi();
        //setRefreshCheckLogin(true);
    }
    const generarQr = () => {
        if(nPersonas != 0){
            if(nPersonas < 1 || nPersonas > 5){
                toast.warning("Numero de personas incorrecto, ingresa un numero como minimo 1 y maximo 5");
            }else{
                nuevoPersonal(nPersonas).then(response =>{
                    if(response?.mensaje){
                        toast.success(response?.mensaje);
                        obtieneListado()
                        obtieneEstatus()
                    }else{
                        toast.warning(response?.error);
                    }
                }).catch(() =>{
                })
            }
            
        }else{
            toast.warning("Ingresa el numero de personas");
        }
        
    }

    const sumarN = () => {
        setNPersonas(nPersonas + 1);
    }

    const restarN = () => {
        if(nPersonas > 0){
            setNPersonas(nPersonas - 1);
        }
    }

    const abrirQR = (token) => {
        setTokenQR(token);
        setShowModalQR(true);
    }

    const eliminarQR = (token) => {
        eliminaQrMetropases(token).then(response =>{
            if(response?.mensaje){
                toast.success(response?.mensaje);
                obtieneListado()
            }else{
                toast.warning(response?.error);

            }
            obtieneListado()
        }).catch(() =>{
            
        })
    }

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    

//<img className="ico-titulos" src={irAtras} alt="Ir atras" />
//
    return (
    <React.Fragment>
    <BasicLayout page="personal" className="user" setRefreshCheckLogin={setRefreshCheckLogin} >
        <div className="user__pases">
          <Link to="/#">
          <img className="logo" src={MetropolitanoPng} alt="Logo" />
          </Link>

          <div className="sec-salir-pasaportes">
                <Link to={`/${SUB_DIR}personal`} onClick={logout}>
                <div className="float-right caja-salirr movil-btn-o"><img className="salirr" src={IcoSalir} alt="Salir" /> Salir</div>
                </Link>
            </div>

          <div className="divisor"><h5><Link to={`/${SUB_DIR}metropases`} ><img className="ico-titulos" src={icoMetropases} alt="Metropases" />Metropases</Link> / <img className="ico-titulos" src={icoPersonal} alt="Metropases" /><span>Personal</span></h5></div>

          <div className="row justify-content-center">
              <div className="col-sm-5 text-center creditos-restantes pt-2">
                  <label>{estatus?.mensaje}</label>
                  {estatus?.credito > 0 ? 
                  <span>ENTRADAS RESTANTES: <span className="badge badge-info pt-1">{estatus?.credito}</span></span>
                   : ""}
                  
              </div>
          </div>

          {estatus?.credito > 0 ? 
            <div className="text-center caja-gen-codigo">
            <button type="button" className="btn btn-success mb-2 btn-generarcodigop" >
              <div className="row justify-content-center">
                  <div className="col-8">
                      <div className="form-group pt-4">
                          <label htmlFor="npersonas">Núm. de personas</label>
                          

<div className="input-group inline-group">
  <div className="input-group-prepend">
    <div className="btn btn-outline-primary" onClick={restarN}>
        <FontAwesomeIcon icon={faMinus} />
    </div>
  </div>

                        <Form.Control 
                          readOnly
                          value={nPersonas}
                          name="creditos"
                          className="text-center"
                          type="number" 
                          min="0"
                          id="npersonas"
                          placeholder="Núm. de personas"
                           />
  <div className="input-group-append">
    <div className="btn btn-outline-primary" onClick={sumarN}>
        <FontAwesomeIcon icon={faPlus} />
    </div>
  </div>
</div>
                      </div>
                      
                  </div>
                  <div className="col text-center" onClick={generarQr}>
                      <img className="img-qrr" src={icoQR} alt="Generar qr" />
                      GENERAR CÓDIGO
                  </div>
              </div>
                
            </button>
            </div>: ''}
          

          
            { Object.keys(pasaportes).length > 0 ? <div className="table-responsive">
          <table className="table tbl-codigos-qr">
            <thead>
                <tr>
                    <th scope="col">Credito</th>
                    <th scope="col">Fecha creación</th>
                    <th scope="col">Vigencia</th>
                    <th scope="col" >Ver</th>
                    <th scope="col">Borrar</th>
                </tr>
            </thead><tbody>
                {map(pasaportes, (pasaporte, index) =>(
                    <Pasaporte key={index} pasaporte={pasaporte} usuario={usuario} abrirQR={abrirQR} eliminarQR={eliminarQR}  />
                ))}
            </tbody></table>
          </div> : <div className="text-center creditos-restantes pt-4" ><h5>NO HAY CÓDIGOS</h5></div>}
          

          
        </div>
    </BasicLayout>
    <BasicModal show={showModalQR} setShow={setShowModalQR} tipo={"qrpersonal"} token={tokenQR} />
    </React.Fragment>
    )
}

function initialFormValue() {
    return {
        creditos: 0,
    }
}

function Pasaporte(props) {
    const{ pasaporte, usuario, abrirQR, eliminarQR } = props;
    var vigencia = pasaporte?.vigencia.split("T");
    var d = new Date(pasaporte?.vigencia);
    var horas = d.getHours()
    var minutos = d.getMinutes()
    var creacion = pasaporte?.created_at.split("T");
    return (
        <tr>
            <td> {pasaporte?.creditos_by_usuario+" entrada(s)"} </td>
            <td>{creacion[0]}</td>
            <td>{vigencia[0]+" "+horas+":"+minutos+" hrs."}</td>
            <td><img onClick={() => abrirQR({"token":pasaporte?.token,"inicio":creacion[0],"fin":vigencia[0]})} className="ico-titulos acciones-metros" src={icoVer} alt="Metropases" /></td>
            <td><img onClick={() => eliminarQR(pasaporte?.token)} className="ico-titulos acciones-metros" src={icoEliminar} alt="Metropases" /></td>
        </tr>
    );
}

