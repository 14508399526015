import React, { useState, useEffect } from 'react';
import BasicLayout from "../../layout/BasicLayout";
import { getPasapets, getMetropases } from "../../api/pasaportes";
import MetropolitanoPng from "../../assets/png/parque-metropolitano.png";
import icoPasaportes from "../../assets/svg/pasaportes.svg";

import icoMetropases from "../../assets/svg/metropases-secc.svg";
import icoEstacionamientos from "../../assets/svg/outline-estacionamiento.png";
import icoPersonal from "../../assets/svg/outline-accesos.png";
import { map } from "lodash";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { Link } from "react-router-dom";
import IcoSalir from "../../assets/svg/salir.svg";
import { SUB_DIR,API_HOST } from "../../utils/constant";
import { logoutApi } from "../../api/auth";
import IcoPaseNuevo from "../../assets/svg/pase-menu.svg";
import icoParkimovil from "../../assets/svg/parkimovil-logo-h.png";


import "./Metropases.scss";

export default function Metropases(props) {
    const { setRefreshCheckLogin } = props;
    const [dispositivo, setDispositivo] = useState(null)

    useEffect(() => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        //Android detection
        if (/android/i.test(userAgent)) {
            setDispositivo("Android")
            
        }
        // iOS detection 
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setDispositivo("iOS")
            
        }
        
    }, [])

    const logout = () => {
        window.open(`${API_HOST}/logout`, "_self");
        logoutApi();
        //setRefreshCheckLogin(true);
    }
//
    return (
    <BasicLayout page="metropases" className="user" setRefreshCheckLogin={setRefreshCheckLogin} >
        <div className="user__pases">
          <Link to="/#">
          <img className="logo svg-blanco" src={MetropolitanoPng} alt="Logo" />
          </Link>

          <div className="sec-salir-pasaportes">
                <Link to={`/${SUB_DIR}metropases`} onClick={logout}>
                <div className="float-right caja-salirr movil-btn-o"><img className="salirr" src={IcoSalir} alt="Salir" /> Salir</div>
                </Link>
                <Link to={`/${SUB_DIR}#`} >
                <div className="float-right caja-salirr"><img className="salirr" src={IcoPaseNuevo} alt="Pases" /> Ver pases</div>
                </Link>
            </div>

          <div className="divisor"><h5><img className="ico-titulos" src={icoMetropases} alt="Metropases" />Metropases</h5></div>
          <Container>
            <Row className="botones-pasapor mt-4">
                <div className="col-sm-6 text-center mb-4">
                    <div className="h5 text-left titulo-leyv">
                        Acceso
                    </div>
                    <div className="text-left mb-1 txt-leyenda-pase">
                        Genera un código para hacer válido tu metropase. Puedes incluir un acceso de tus acompañantes.
                    </div>
                    <Link to="personal">
                        <div className="btn mb-2 btn-personal pb-2 pt-2 pr-3 pl-3"><img className="ico-titulos" src={icoPersonal} alt="Metropases" />Acceso</div>
                    </Link>
                </div>
                <div className="col-sm-6 text-center mb-4">
                    <div className="h5 text-left titulo-leyv">
                        Estacionamiento
                    </div>
                    <div className="text-left mb-1 txt-leyenda-pase">
                        Genera un código para hacer válido tu metropase de estacionamiento.
                    </div>
                <Link to="estacionamiento">
                    <div className="btn mb-2 btn-estacionamiento pb-2 pt-2 pr-3 pl-3"><img className="ico-titulos" src={icoEstacionamientos} alt="Metropases" />Estacionamiento</div>
                </Link>
                <br></br>
                

                
                    <div className="text-left mb-1 txt-leyenda-pase mt-4">
                    ¿Ingresas por el acceso Poniente (Balcones)? Para tu comodidad puedes descargar la APP Parkimovil desde tu celular para ingresar más rápido a este estacionamiento
                    </div>
                
               
                <Link to={dispositivo == "iOS" ? "//apps.apple.com/es/app/parkimovil/id954503274" : "//play.google.com/store/apps/details?id=com.parkimovil.app&hl=es_419"} target="_blank">
                    <img className="ico-bigs ico-bigs-servicios ico-parkimovil" src={icoParkimovil} alt="Parkimovil" />
                </Link>
                </div>
            </Row>
          </Container>
          <div>
              
              
              
          </div>

        </div>
    </BasicLayout>
    )
}


