import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import { values, size, map } from "lodash";
import { toast } from "react-toastify";
import { isEmailValid } from "../../../utils/validations";
import { generarPaseUserApi } from "../../../api/user";
import { getAcompanantes } from "../../../api/acompanantes";
import { getActividadesApi, getTransporteApi, getHorariosApi } from "../../../api/utils";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es"; 
import Select from 'react-select';
import IcoCerrar from "../../../assets/svg/cerrar.svg";
import IcoNPase from "../../../assets/svg/pase-menu.svg";
import makeAnimated from 'react-select/animated';
import { Link } from "react-router-dom";
import BotonPase from "../../../assets/svg/boton-generar.svg";


import "./PaseModal.scss"

const animatedComponents = makeAnimated();

export default function PaseModal(props) {
    
    const { show, setShow, user, setRefreshPases } = props;
    const [formData, setFormData] = useState(initialFormValue());
    const [signUpLoading, setSignUpLoading] = useState(false);
    const [selectedActividades, setSelectedActividades] = useState(null);
    const [selectedTransporte, setSelectedTransporte] = useState(null);
    const [selectedHorarios, setSelectedHorarios] = useState(null);
    const [selectedAcompanante, setSelectedAcompanante] = useState(null);

    const [catalogoActividades, setCatalogoActividades] = useState([]);
    const [catalogoTransporte, setCatalogoTransporte] = useState(null);
    const [catalogoHorarios, setCatalogoHorarios] = useState([]);
    const [acompanantes, setAcompanantes] = useState(null);


    useEffect(() => {
        
        getTransporteApi().then(response => {
            if(response){
                const arrayOfObj = response;
                const arrayActividades = arrayOfObj.map(({ tTransporteID: value, tNombre: label, ...rest }) => ({ value, label, ...rest }));
                setCatalogoTransporte(arrayActividades);
            }
        })
        .catch(() => {
            console.log("error al obtener catalogo transporte")
        });
        

    }, []);
    useEffect(() => {
        if(show === true){
            getAcompanantes().then(response => {
                if(response){
                    
                    const arrayOfObj = response.mensaje;
                    const arrayAcompanantes = arrayOfObj.map(({ acoID: value, nombre: label, ...rest }) => ({ value, label, ...rest }));
                    setAcompanantes(arrayAcompanantes)
                }
            })
            .catch(() => {
                console.log("error al obtener acompañantes")
            });
        }
        
    }, [show]);

    


    const onSubmit = e => {
        e.preventDefault();

        let validCount = 0
        values(formData).some(value => {
            value && validCount++;
            return null;
        });

        //if(validCount !== size(formData)){
        if(selectedActividades?.length < 1 || selectedActividades == null || selectedTransporte == null || selectedHorarios?.length < 1 || selectedHorarios == null ||  formData.fecha_visita == null){
            toast.warning("Completa todos los campos del formulario")
        }else{
            setSignUpLoading(true);
            generarPaseUserApi(formData).then(response => {
                
                if(response?.mensaje){
                    
                    setRefreshPases(true);
                    closeModal();
                    toast.success(response?.mensaje);
                    
                }else{
                    toast.warning(response?.error);

                }
            })
            .catch((err) => {
                console.log(err)
                
            })
            .finally(() => {
                setSignUpLoading(false);
            })
        }
    };

    const closeModal = e => {
        setFormData(initialFormValue());
        setSelectedActividades(null);
        setSelectedTransporte(null);
        setSelectedHorarios(null)
        setSelectedAcompanante(null)
        setShow(false);
        
    };

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    };

    const handleChangeActividades = selectedActividades => {
        let dia = ('0' + formData.fecha_visita.getDate()).slice(-2);
        let anio = formData.fecha_visita.getFullYear();
        let mes = ('0' + (formData.fecha_visita.getMonth()+1)).slice(-2);
        let fecha = anio+'-'+mes+'-'+dia;
        console.log(selectedActividades?.tipo)

        setSelectedHorarios([])
        getHorariosApi(selectedActividades?.tipo,fecha).then(response => {
            //console.log(response)
            if(response){
                const arrayOfObj = response;
                const arrayHorarios = arrayOfObj.map(({ hHorariosID: value, hHorariosNombre: label, ...rest }) => ({ value, label, ...rest }));
                setCatalogoHorarios(arrayHorarios)
            }
        })
        .catch(() => {
            console.log("error al obtener catalogo horarios")
        });
        
        setSelectedActividades(selectedActividades);
        setFormData({...formData, actividadID: selectedActividades.value})
    };
    const handleChangeTransporte = selectedTransporte => {
        setSelectedTransporte(selectedTransporte);
        setFormData({...formData, transporteID: selectedTransporte.value})
    };
    const handleChangeHorarios = selectedHorarios => {
        setSelectedHorarios(selectedHorarios);
        setFormData({...formData, horarioID: selectedHorarios.value})
    };
    const handleChangeAcompanante = selectedAcompanante => {
        
        if(selectedAcompanante.length <= 20){
            if(selectedAcompanante != null){
                const arrayOfObj = selectedAcompanante;
                const arrayUpdateApi = arrayOfObj.map(({ value: id, ...rest }) => ({ id, ...rest }));
            var mappedArrayData = arrayUpdateApi.map(({id}) => {
                return {id};
             })
            setFormData({...formData, acompanantesids: mappedArrayData})
            }else{
                setFormData({...formData, acompanantesids: selectedAcompanante})
            }
            setSelectedAcompanante(selectedAcompanante);
        }else{
            toast.warning("El máximo de acompañantes es 20")
        }
        

    };

    const handleFechaVisita = (date) => {
        const day = date.getDay();
        let dia = ('0' + date.getDate()).slice(-2);
        let anio = date.getFullYear();
        let mes = ('0' + (date.getMonth()+1)).slice(-2);
        let fecha = anio+'-'+mes+'-'+dia;

        setSelectedHorarios([])
        setSelectedActividades([])
        setCatalogoHorarios([])
        setFormData({...formData, horarioID: null, actividadID: null})

        getActividadesApi(fecha).then(response => {
            if(response){
                const arrayOfObj = response;
                const arrayActividades = arrayOfObj.map(({ aActividadesID: value, aNombre: label, ...rest }) => ({ value, label, ...rest }));
                setCatalogoActividades(arrayActividades)
            }
        })
        .catch(() => {
            console.log("error al obtener catalogo actividades")
        });

        setFormData({...formData, fecha_visita: date})
    }
    /*const isWeekday = (date: Date) => {
        const day = date.getDay()
        return day !== 0 && day !== 6 && day !== 1
      }*/
      //filterDate={isWeekday}
    
    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }

    return (
        <Modal 
        className="pase-modal"
        show ={show}
        onHide= { () => closeModal() }
        centered
        size="lg"
        >
            <Modal.Header>
                <Modal.Title>
                    <img src={IcoNPase} alt="Nuevo pase" />
                    <label>Nuevo Pase</label>
                    <img className="cerrar" onClick={() => closeModal()} src={IcoCerrar} alt="Cerrar" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

<Form onSubmit={onSubmit}>

<Form.Group>
    <DatePicker
        dateFormat="yyyy/MM/dd"
        placeholderText="Fecha de visita"
        locale={es}
        minDate={new Date()}
        maxDate={addDays(new Date(), 31)}
        
        selected={formData.fecha_visita}
        //onChange={value => setFormData({...formData, fecha_visita: value}) }
        //onChange={(date) => setFormData({...formData, fecha_visita: date})}
        onChange={(date) => handleFechaVisita(date) }
     />
</Form.Group>

<Form.Group>
    <Select
        placeholder="¿Qué actividad vas a realizar?"
        value={selectedActividades}
        onChange={handleChangeActividades}
        options={catalogoActividades}
        isOptionDisabled={(option => option.label === 'PASEO CON MASCOTA') (option => option.label === 'PATINAJE')}
      />
</Form.Group>

<Form.Group>
    <Select
        placeholder="¿Cómo llegas al parque?"
        value={selectedTransporte}
        onChange={handleChangeTransporte}
        options={catalogoTransporte}
      />
</Form.Group>



<Form.Group>
    <Select
        placeholder="¿Cuál será tu horario de entrada al parque?"
        value={selectedHorarios}
        onChange={handleChangeHorarios}
        options={catalogoHorarios}
      />
</Form.Group>



<Form.Group>
    <Select
        placeholder="¿Quién te acompaña?"
        value={selectedAcompanante}
        onChange={handleChangeAcompanante}
        options={acompanantes}
        isMulti
        closeMenuOnSelect={false}
        components={animatedComponents}
      />
    <Form.Text className="text-muted">
       {acompanantes?.length === 0 ? 
      <div>no tienes acompañantes registrados, <Link onClick={() => closeModal()}  to={'/acompanante'} >clic aquí para agregar</Link></div>  : ''}
    </Form.Text> 
</Form.Group>




<div className="updt text-center">

{!signUpLoading ? <img className="nuevo-aco" onClick={onSubmit} src={BotonPase} alt="Generar" /> :
 <Spinner animation="border" />}
</div>
</Form>

            </Modal.Body>
        </Modal>
    )
}
//fecha_nac: "1995-07-07"
function initialFormValue() {
    return {
        horarioID: "",
        actividadID: "",
        transporteID: "",
        acompanante: "",
        edadAcompanante: null,
        fecha_visita: null,
        acompanantesids: null
    }
}
